import { SVGProps } from "react";

type Props = SVGProps<SVGSVGElement> & {
  title?: string;
};

/**
 * Optimized with https://jakearchibald.github.io/svgomg/
 */
const SVGHamburger: React.FC<Props> = (props) => {
  const { title, ...rest } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 22 14"
      {...rest}
    >
      <title>{title}</title>
      <path
        fill="currentColor"
        d="M1 12.5a.5.5 0 0 0 0 1v-1Zm20 1a.5.5 0 0 0 0-1v1Zm-20-7a.5.5 0 0 0 0 1v-1Zm20 1a.5.5 0 0 0 0-1v1ZM1 .5a.5.5 0 0 0 0 1v-1Zm20 1a.5.5 0 0 0 0-1v1Zm-20 12h20v-1H1v1Zm0-6h20v-1H1v1Zm0-6h20v-1H1v1Z"
      />
    </svg>
  );
};

export default SVGHamburger;
