import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type Tab = any;

interface DesktopMenuState {
  isOpen: boolean;
  activeTab?: Tab;
}

const initialState: DesktopMenuState = {
  isOpen: false,
};

export const desktopMenuSlice = createSlice({
  name: "desktopMenu",
  initialState,
  reducers: {
    openTab: (state, action: PayloadAction<Tab>) => {
      state.isOpen = true;
      state.activeTab = action.payload;
    },
    close: (state) => {
      state.isOpen = false;
      state.activeTab = null;
    },
  },
});

export const { openTab, close } = desktopMenuSlice.actions;

export default desktopMenuSlice.reducer;
