import styled from "styled-components";

export const Container = styled.div<{ $desktopMenuIsOpen?: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 10;

  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${({ theme }) => theme.space.headerMinHeight};
  box-sizing: border-box;

  transition: all 0.3s;
  background-color: ${({ theme }) => theme.colors.white};

  ${({ theme }) => theme.mediaQueries.laptop} {
    padding: 0 2.5rem;
    background-color: ${({ theme, $desktopMenuIsOpen }) =>
      $desktopMenuIsOpen ? theme.colors.black : theme.colors.white};
  }
`;

export const Controls = styled.div`
  display: flex;
  align-items: center;
`;
