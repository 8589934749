import { isAnyOf } from "@reduxjs/toolkit";
import { useEffect } from "react";
import { store, addAppListener } from "@/lib/redux/store";
import {
  wishlistSchema,
  createWishlist,
  wishlistLineAdd,
  wishlistLineRemove,
  wishlistLineUpdate,
} from "../redux/wishlist/wishlistSlice";

const useWishlistSync = () => {
  useEffect(() => {
    const rawWishlist = localStorage.getItem("wishlist");
    if (rawWishlist) {
      try {
        // Read wishlist from session storage and copy it into the store
        const wishlist = wishlistSchema.parse(JSON.parse(rawWishlist));
        store.dispatch(createWishlist(wishlist));
      } catch (error) {
        console.error(error);
      }
    }
    const unsubscribe = store.dispatch(
      addAppListener({
        matcher: isAnyOf(
          wishlistLineAdd,
          wishlistLineRemove,
          wishlistLineUpdate
        ),
        async effect(_, listenerApi) {
          // Can cancel other running instances
          listenerApi.cancelActiveListeners();
          // Get wishlist state
          const { wishlist } = listenerApi.getState();
          // Sync it with localStorage
          localStorage.setItem("wishlist", JSON.stringify(wishlist));
        },
      })
    );
    return () => {
      unsubscribe();
    };
  }, []);
};

export default useWishlistSync;
