import { useAppDispatch, useAppSelector } from "@/lib/redux/hooks";
import { useEffect, useRef } from "react";
import { CSSTransition } from "react-transition-group";
import Header from "./Header";
import Content from "./Content";
import { Container } from "./styles";
import { useRouter } from "next/router";
import { close } from "@/lib/redux/mobileMenu/mobileMenuSlice";
import {
  enableBodyScroll,
  disableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock";
import { MainLayoutQuery } from "@/lib/sanity/queries";

type Props = {
  items: MainLayoutQuery["header"]["navigation"]["items"];
};

const MobileMenu: React.FC<Props> = (props) => {
  const { items } = props;
  const isOpen = useAppSelector((state) => state.mobileMenu.isOpen);
  const containerRef = useRef<HTMLDivElement>(null);

  const dispatch = useAppDispatch();
  const router = useRouter();

  useEffect(() => {
    const onRouteChangeStart = () => {
      dispatch(close());
    };
    router.events.on("routeChangeStart", onRouteChangeStart);
    return function cleanup() {
      router.events.off("routeChangeStart", onRouteChangeStart);
    };
  }, [dispatch, router]);

  useEffect(() => {
    // Create closure around mutable reference
    const container = containerRef.current;
    if (!container) return;

    if (isOpen) {
      disableBodyScroll(container);
    } else {
      enableBodyScroll(container);
    }
    return function cleanup() {
      // Make sure all scroll locks are deleted
      clearAllBodyScrollLocks();
    };
  }, [isOpen]);

  return (
    <CSSTransition
      in={isOpen}
      nodeRef={containerRef}
      classNames="animation"
      addEndListener={(done) => {
        containerRef?.current?.addEventListener("transitionend", done, false);
      }}
    >
      <Container ref={containerRef}>
        <Header />
        <Content items={items} />
      </Container>
    </CSSTransition>
  );
};

export default MobileMenu;
