import styled from "styled-components";

export const ContactBadgeContainer = styled.div<{ $visible: boolean }>`
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 1rem;
  width: 64px;
  height: 64px;
  z-index: 5;
  display: grid;
  place-items: center;
  cursor: pointer;
  opacity: ${({ $visible }) => ($visible ? 1 : 0)};
  pointer-events: ${({ $visible }) => ($visible ? "visible" : "none")};
`;

export const Badge = styled.a`
  position: absolute;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-color: ${({theme})=>theme.colors.red};
  display: flex;
  justify-content: center;
  align-items: center;
  
  svg {
    stroke: white;
    height: 30px;
  }
`;
