import Link from "next/link";
import { SVGLogo } from "@/lib/svg";
import { Anchor } from "./styles";

const Logo = () => {
  return (
    <Link href="/" passHref>
      <Anchor>
        <SVGLogo />
      </Anchor>
    </Link>
  );
};

export default Logo;
