import React from "react";
import { SVGArrowRight } from "@/lib/svg";
import CallToAction from "@/components/Button";
import styled from "styled-components";

export const Container = styled.nav`
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  margin: 0 1rem;

  // fix for MobileNav bottom space
  > *:last-child {
    margin-bottom: 2.5rem;
  }
`;

export const Anchor = styled.a`
  color: ${({ theme }) => theme.colors.white};
  text-decoration: none;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 1.2;
`;

export const Button: React.FC<{ children: string; href?: string; target?: string }> = (
  props
) => (
  <CallToAction
    as="a"
    variant="primaryBoxed"
    {...props}
    icon={<SVGArrowRight />}
  >
    {props.children}
  </CallToAction>
);
