import { SVGProps } from "react";

type Props = SVGProps<SVGSVGElement>;

/**
 * Optimized with https://jakearchibald.github.io/svgomg/
 */
const SVGWishlistDot: React.FC<Props> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 11 11"
    {...props}
  >
    <title>Wishlist Dot</title>
    <circle
      cx="5.5"
      cy="5.5"
      r="5.5"
      fill="#C11113"
      stroke="#fff"
      strokeWidth="2"
      opacity={0}
    />
  </svg>
);

export default SVGWishlistDot;
