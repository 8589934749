import React from "react";
import { MainLayoutQuery } from "@/lib/sanity/queries";
import { useAuth } from "@/lib/hooks/useAuth";
import DefaultHeader from "@/components/MainLayout/Header/DefaultHeader";
import PrivateHeader from "@/components/MainLayout/Header/PrivateHeader";
import { useRouter } from "next/router";

type Props = {
  navigation: MainLayoutQuery["header"]["navigation"];
};

const Header: React.FC<Props> = (props) => {
  const { user } = useAuth();
  const router = useRouter();

  const showPrivate =
    user && user?.emailVerified && user?.data?.approved &&
    router.pathname === "/investors" || router.pathname === "/investors/profile";

  return showPrivate ? <PrivateHeader /> : <DefaultHeader {...props} />;
};

export default Header;
