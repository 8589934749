import {
  AppDispatch,
  productFilterOptionSelectors,
  RootState,
} from "@/lib/redux/store";
import { useRouter } from "next/router";
import { useCallback } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { searchApiFetcher } from "../algolia";
import {
  ProductFilter,
  updateManyProductFilterOptions,
} from "./productFilters/productFiltersSlice";

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useTriggerProductFilterOptionRecount = () => {
  const { query } = useRouter();
  const dispatch = useAppDispatch();

  const updateFilterCount = useCallback(
    async (filter: ProductFilter) => {
      // We don't read from the SWR cache because it makes
      // the component difficult to predict.
      // This is OK because /api/search uses HTTP caches
      const data = await searchApiFetcher({
        ...query,
        [filter.id]: [],
      });
      const filterOptionCounts = data.facets[filter.id];
      const optionIds = productFilterOptionSelectors.selectIds(filter);
      dispatch(
        updateManyProductFilterOptions({
          filterId: filter.id,
          updates: optionIds.map((optionId) => ({
            id: optionId,
            changes: {
              count: filterOptionCounts?.[optionId] || 0,
            },
          })),
        })
      );
    },
    [query, dispatch]
  );
  return updateFilterCount;
};
