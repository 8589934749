import { SVGProps } from "react";

type Props = SVGProps<SVGSVGElement>;

/**
 * Optimized with https://jakearchibald.github.io/svgomg/
 */
const SVGPlusAccordion: React.FC<Props> = (props) => (
  <svg viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 16.5a.5.5 0 0 0 0 1v-1Zm32 1a.5.5 0 0 0 0-1v1ZM17.5 1a.5.5 0 0 0-1 0h1Zm-1 32a.5.5 0 0 0 1 0h-1ZM1 17.5h32v-1H1v1ZM16.5 1v32h1V1h-1Z"
      fill="#C11113"
    />
  </svg>
);

export default SVGPlusAccordion;
