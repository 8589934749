import { fetcher } from "@/lib/helpers";
import { useAppSelector } from "@/lib/redux/hooks";
import {
  getUser,
  updateUser,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  confirmPasswordReset,
  createUserWithEmailAndPassword,
} from "@/lib/firebase_middleware";
import { createContext } from "react";
import { z } from "zod";
import useSWR from 'swr';

export const userDataSchema = z
  .object({
    uid: z.string(),
    email: z.string(),
    firstName: z.string(),
    lastName: z.string(),
    company: z.string(),
    county: z.string(),
    country: z.string(),
    role: z.string(),
    city: z.string(),
    approved: z.boolean(),
    emailVerified: z.boolean()
  })
  .partial();

export type UserData = z.infer<typeof userDataSchema>;

export type User = {
  uid: string;
  email: string | null;
  emailVerified: boolean;
  displayName: string | null;
  data: UserData;
};

export async function getUserData(userId: string) {
  return await getUser(userId)
}

export async function updateUserData(userId: string, data: UserData) {
  return await updateUser(userId, data)
}

export async function signupWithData(
  email: string,
  password: string,
  data: UserData
) {
  console.log("Signup submit api signupWithData start")
  const userCredential = await createUserWithEmailAndPassword(
    email,
    password,
    data
  );
  console.log("Signup submit api signupWithData after user creation")
}

export async function login(email: string, password: string) {
  console.log("Login submit api login start")
  const resultLogin = await fetcher('/api/firebase/login', { email, password })
  console.log("Login submit api login end")

  if (!resultLogin.uid) {
    throw new Error(resultLogin)
  }

  return resultLogin
}

export const sendPasswordReset = async (email: string) => {
  return await sendPasswordResetEmail(email);
};

export const resetPassword = async (oobCode: string, password: string) => {
  return await confirmPasswordReset(oobCode, password);
};

export const logout = async () => {
  return await fetcher('/api/firebase/logout', {})
};

/** @deprecated use signupWithData */
export async function signup(email: string, password: string, data: UserData) {
  await signupWithData(email, password, data)
}

type AuthContext = {
  user?: User | null;
  clearUser?: any | null;
  updateUser?: any | null;
};

export const defaultValue: AuthContext = {
};

export const AuthContext = createContext<AuthContext>(defaultValue);
