import { smallLabelTypographicStyle } from "@/lib/styles/helpers";
import styled from "styled-components";

export const Container = styled.nav`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  order: 2;
  ${smallLabelTypographicStyle}
  font-weight: 700;

  ${({ theme }) => theme.mediaQueries.tablet} {
    flex-direction: row;
    align-items: center;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    order: 2;
  }
`;
