import { MainLayoutQuery } from "@/lib/sanity/queries";
import dynamic from "next/dynamic";
import { Container } from "./styles";

const MenuTab = dynamic(() => import("./MenuTab"));
const InternalLink = dynamic(() => import("./InternalLink"));
const ExternalLink = dynamic(() => import("./ExternalLink"));

type Props = {
  items: MainLayoutQuery["header"]["navigation"]["items"];
};

const Content: React.FC<Props> = (props) => {
  const { items } = props;
  return (
    <Container>
      {items?.map((item) => {
        switch (item?._type) {
          case "headerTab":
            return <MenuTab key={item._key} tab={item} />;
          case "internalLink":
            return <InternalLink key={item._key} {...item} />;
          case "externalLink":
            return <ExternalLink key={item._key} {...item} />;
          default:
            return null;
        }
      })}
    </Container>
  );
};

export default Content;
