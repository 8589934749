import styled from "styled-components";

export const Container = styled.div<{ $desktopMenuIsOpen?: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 10;

  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${({ theme }) => theme.space.headerMinHeight};
  box-sizing: border-box;

  transition: all 0.3s;
  background-color: ${({ theme }) => theme.colors.white};

  ${({ theme }) => theme.mediaQueries.laptop} {
    padding: 0 2.5rem;
    background-color: ${({ theme, $desktopMenuIsOpen }) =>
      $desktopMenuIsOpen ? theme.colors.black : theme.colors.white};
  }
`;

export const Controls = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const LocaleSelectorWrapper = styled.div<{
  $desktopMenuIsOpen?: boolean;
}>`
  .react-select__control {
    background: none;
    border: none;
  }

  .react-select__single-value,
  .react-select__dropdown-indicator,
  .react-select__dropdown-indicator:hover {
    transition: color 0.3s;
    color: ${({ theme, $desktopMenuIsOpen }) =>
      $desktopMenuIsOpen ? theme.colors.white : theme.colors.black};
  }

  .react-select__dropdown-indicator {
    box-sizing: content-box;
  }
  .react-select__indicator-separator {
    display: none;
  }
  .react-select__value-container {
    padding-right: 0;
  }
`;
