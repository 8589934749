import { SVGProps } from "react";

type Props = SVGProps<SVGSVGElement>;

/**
 * Optimized with https://jakearchibald.github.io/svgomg/
 */
const SVGCarouselPlus: React.FC<Props> = (props) => (
  <svg
    viewBox="0 0 46 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.668 22a1 1 0 0 0 0 2v-2Zm42.667 2a1 1 0 1 0 0-2v2ZM24 1.666a1 1 0 1 0-2 0h2Zm-2 42.667a1 1 0 1 0 2 0h-2ZM1.668 24h42.667v-2H1.668v2ZM22.001 1.666v42.667h2V1.667h-2Z"
      fill="#fff"
    />
  </svg>
);

export default SVGCarouselPlus;
