import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface NewsSearchState {
  query?: string;
}

const initialState: NewsSearchState = {};

export const newsSearchSlice = createSlice({
  name: "newsSearch",
  initialState,
  reducers: {
    setNewsSearchQuery: (state, action: PayloadAction<string>) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.query = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setNewsSearchQuery } = newsSearchSlice.actions;

export default newsSearchSlice.reducer;
