import styled from "styled-components";

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;

  svg {
    width: 0.9em;
    height: auto;
  }
`;
