import "@/lib/fonts.css";
import "reset.css";
import "plyr-react/dist/plyr.css";

import type { AppProps, NextWebVitalsMetric } from "next/app";
import { useEffect } from "react";
import MainLayout from "@/components/MainLayout";
import { Provider } from "react-redux";
import * as gtag from "@/lib/gtag";
import dynamic from "next/dynamic";
import { store } from "@/lib/redux/store";
import { GlobalStyles, myTheme } from "@/lib/styles";
import GoogleAnalytics from "@/components/GoogleAnalytics";
import { ThemeProvider } from "styled-components";
import useWishlistSync from "@/lib/hooks/useWishlistSync";
import AuthProvider from "@/components/AuthProvider";
import config from "package.json";
import ContactBadge from "@/components/ContactBadge";
import CookieBotApp from "@/components/CookiebotApp";

const PreviewFlag = dynamic(() => import("@/components/PreviewFlag"));

function MyApp({ Component, pageProps, router }: AppProps) {
  const Layout = (Component as any).Layout || MainLayout;
  const layoutData = (pageProps?.layout as Record<string, any>) || {};

  useEffect(() => {
    // Print out package name and version
    // eslint-disable-next-line no-console
    console.log(
      `%c${config.name}@${config.version}`,
      `color: ${myTheme.colors.red}`
    );
  }, []);

  useWishlistSync();

  return (
    <>
      <CookieBotApp />
      <GoogleAnalytics />
      <ThemeProvider theme={myTheme}>
        <GlobalStyles />
        <AuthProvider>
          <Provider store={store}>
            <Layout data={layoutData}>
              <ContactBadge />
              <Component {...pageProps} />
            </Layout>
          </Provider>
        </AuthProvider>
        {router.isPreview && <PreviewFlag />}
      </ThemeProvider>
    </>
  );
}

export default MyApp;

export function reportWebVitals({
  id,
  name,
  label,
  value,
}: NextWebVitalsMetric) {
  // Use `window.gtag` if you initialized Google Analytics as this example:
  // https://github.com/vercel/next.js/blob/canary/examples/with-google-analytics/pages/_app.js
  gtag.event({
    action: name,
    category: label === "web-vital" ? "Web Vitals" : "Next.js custom metric",
    value: Math.round(name === "CLS" ? value * 1000 : value), // values must be integers
    label: id, // id unique to current page load
    non_interaction: true, // avoids affecting bounce rate.
  });
}
