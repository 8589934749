import { SVGProps } from "react";

type Props = SVGProps<SVGSVGElement>;

/**
 * Optimized with https://jakearchibald.github.io/svgomg/
 */
const SVGMinusAccordion: React.FC<Props> = (props) => (
  <svg viewBox="0 0 34 2" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 .5a.5.5 0 0 0 0 1v-1Zm32 1a.5.5 0 0 0 0-1v1Zm-32 0h32v-1H1v1Z"
      fill="#C11113"
    />
  </svg>
);

export default SVGMinusAccordion;
