import styled from "styled-components";

export const Anchor = styled.a`
  color: ${({ theme }) => theme.colors.white};
  display: block;
  width: 11rem;
  height: auto;
  margin-bottom: 2rem;

  // Correct baseline
  ${({ theme }) => theme.mediaQueries.tablet} {
    transform: translateY(28%);
    margin-bottom: 0;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    margin-right: 2rem;
  }

  svg path {
    fill: currentColor;
  }
`;
