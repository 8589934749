import {
  mainLayoutQuery,
  MainLayoutQuery,
  mainLayoutQuerySchema,
} from "@/lib/sanity/queries";
import { sanityClient } from "@/lib/sanity/server";
import { useRouter } from "next/router";
import { ReactNode, useMemo, useRef } from "react";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import useSWR from "swr";
import Footer from "./Footer";
import Header from "./Header";
import { Container, Content } from "./styles";

type Props = {
  children: ReactNode;
  data: MainLayoutQuery;
};

const fetcher = async (
  query: string,
  params: {
    locale: string;
    defaultLocale: string;
  }
) => {
  const res = await sanityClient.fetch(query, params);
  return mainLayoutQuerySchema.parse(res);
};

const MainLayout: React.FC<Props> = (props) => {
  const { children } = props;
  const { pathname, locale = "it", defaultLocale = "it" } = useRouter();

  const { data } = useSWR(
    [
      mainLayoutQuery,
      {
        locale,
        defaultLocale,
      },
    ],
    fetcher,
    {
      fallbackData: props.data,
    }
  );

  const { header, footer } = data || {};

  const transitionKey = useMemo(
    () => pathname + "-" + locale,
    [pathname, locale]
  );
  const contentRef = useRef<HTMLDivElement>(null);
  return (
    <Container id="main-container">
      {header && <Header {...header} />}
      <SwitchTransition>
        <CSSTransition
          key={transitionKey}
          nodeRef={contentRef}
          classNames="animation"
          addEndListener={(done) => {
            // Pass false as third param to make sure event listener runs only one
            contentRef.current?.addEventListener("transitionend", done, false);
          }}
        >
          <Content ref={contentRef}>{children}</Content>
        </CSSTransition>
      </SwitchTransition>
      {footer && <Footer {...footer} />}
    </Container>
  );
};

export default MainLayout;
