import { useAppSelector } from "@/lib/redux/hooks";
import Link from "next/link";
import { SVGLogo } from "@/lib/svg";
import { Anchor } from "./styles";

const Logo = () => {
  const desktopMenuOpen = useAppSelector((state) => state.desktopMenu.isOpen);
  return (
    <Link href={`/`}>
      <Anchor $desktopMenuOpen={desktopMenuOpen}>
        <SVGLogo />
      </Anchor>
    </Link>
  );
};

export default Logo;
