import { MainLayoutQuery } from "@/lib/sanity/queries";
import dynamic from "next/dynamic";
import { Container } from "./styles";

const ExternalLink = dynamic(() => import("./ExternalLink"));
const InternalLink = dynamic(() => import("./InternalLink"));
const SocialLinks = dynamic(() => import("./SocialLinks"));

type Props = {
  items: MainLayoutQuery["footer"]["navigation"]["items"];
};

const Navigation: React.FC<Props> = ({ items }) => {
  return (
    <Container>
      {items?.map((item) => {
        if (!item?._key) return null;
        switch (item?._type) {
          case "externalLink":
            return <ExternalLink key={item._key} {...item} />;
          case "internalLink":
            return <InternalLink key={item._key} {...item} />;
          case "socialLinks":
            return <SocialLinks key={item._key} {...item} />;
          default:
            return null;
        }
      })}
    </Container>
  );
};

export default Navigation;
