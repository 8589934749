import { SVGProps } from "react";

type Props = SVGProps<SVGSVGElement>;

/**
 * Optimized with https://jakearchibald.github.io/svgomg/
 */
const SVGMail: React.FC<Props> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 64 64"
    stroke="currentColor"
    {...props}
  >
    <title>Bormioli Pharma</title>
    <path
      d="m4.001 21.487 23.734 10.04a11.02 11.02 0 0 0 8.532 0l23.734-10.04"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M54.91 53H9.092C6.279 53 4 50.912 4 48.333V15.667c0-2.579 2.278-4.667 5.09-4.667h45.82c2.812 0 5.09 2.088 5.09 4.667v32.666c0 2.579-2.278 4.667-5.09 4.667Z"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SVGMail;
