import styled from "styled-components";

export const Container = styled.div`
  display: none;
  ${({ theme }) => theme.mediaQueries.laptop} {
    display: block;

    position: fixed;
    top: ${({ theme }) => theme.space.headerMinHeight};
    left: 0;
    right: 0;
    z-index: 9;

    background-color: ${({ theme }) => theme.colors.black};
    color: ${({ theme }) => theme.colors.white};

    transform: translateY(-100%);

    &.animation-enter {
      transform: translateY(-100%);
    }
    &.animation-enter-done {
      transform: translateY(0);
    }
    &.animation-enter-active {
      transform: translateY(0);
      transition: transform 200ms 200ms;
    }
    &.animation-exit {
      transform: translateY(0);
    }
    &.animation-exit-active {
      transform: translateY(-100%);
      transition: transform 200ms;
    }
    &.animation-exit-done {
      transform: translateY(-100%);
    }
  }
`;

export const CloseArea = styled.div`
  display: none;
  ${({ theme }) => theme.mediaQueries.laptop} {
    display: block;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    z-index: 8;
  }
`;
