import { SVGProps } from "react";

type Props = SVGProps<SVGSVGElement>;

/**
 * Optimized with https://jakearchibald.github.io/svgomg/
 */
const SVGYoutube: React.FC<Props> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 14"
    {...props}
  >
    <g clipPath="url(#clip0_58_11609)">
      <path d="M19.5831 2.18595C19.469 1.76219 19.2456 1.37582 18.9353 1.06553C18.625 0.755237 18.2386 0.531914 17.8148 0.41792C16.2551 -5.56907e-07 10.0009 0 10.0009 0C10.0009 0 3.74668 -5.56907e-07 2.18712 0.41792C1.76333 0.531878 1.37691 0.755189 1.06658 1.06549C0.756254 1.37578 0.532901 1.76217 0.418896 2.18595C0.0009766 3.74571 0.000976562 6.99992 0.000976562 6.99992C0.000976562 6.99992 0.0009766 10.2541 0.418896 11.8139C0.532901 12.2377 0.756254 12.6241 1.06658 12.9344C1.37691 13.2446 1.76333 13.468 2.18712 13.5819C3.74668 13.9998 10.0009 13.9998 10.0009 13.9998C10.0009 13.9998 16.2551 13.9998 17.8148 13.5819C18.2386 13.4679 18.625 13.2446 18.9353 12.9343C19.2456 12.624 19.469 12.2376 19.5831 11.8139C20.001 10.2541 20.001 6.99992 20.001 6.99992C20.001 6.99992 20.001 3.74571 19.5831 2.18595Z" fill="white"/>
      <path d="M8.00098 10L13.1968 7.00019L8.00098 4V10Z" fill="#25262E"/>
    </g>
  </svg>
);

export default SVGYoutube;
