import Script from "next/script";

const CookiebotApp: React.FC = () => {
  return (
    <Script
      strategy="afterInteractive"
      id="Cookiebot"
      src="https://consent.cookiebot.com/uc.js"
      data-cbid="99761933-b82a-4ddc-9501-e2cd54bd1f54"
      data-blockingmode="auto"
      type="text/javascript"
    />
  );
};

export default CookiebotApp;
