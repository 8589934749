import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "@/lib/context/auth";

export type Logged = {
  isLogged?: User | null
};

const initialState: Logged = {
};

export const loggedSlice = createSlice({
  name: "logged",
  initialState,
  reducers: {
    loginState: (state, action: PayloadAction<User>) => {
      state.isLogged = action.payload;
    },
    logoutState: (state) => {
      state.isLogged = null;
    },
  },
});

export const {
  loginState,
  logoutState,
} = loggedSlice.actions;

export default loggedSlice.reducer;
