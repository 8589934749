import { useAppSelector } from "@/lib/redux/hooks";
import Link from "next/link";
import { Anchor, Dot, DotContainer, Icon } from "./styles";
import { useEffect, useRef } from "react";
import gsap from "@/lib/gsap";

type Timeline = gsap.core.Timeline;

export type WishlistButtonProps = {};

const WishlistButton: React.FC = () => {
  const dotRef = useRef<HTMLDivElement>(null);
  const timelineRef = useRef<Timeline | null>(null);
  const lines = useAppSelector((state) => state.wishlist.lines);
  const menuIsOpen = useAppSelector((state) => state.desktopMenu.isOpen);

  const hasLines = lines.length > 0;

  const runAnimation = () => {
    if (dotRef.current && lines.length > 0) {
      if (timelineRef.current === null) {
        timelineRef.current = gsap.timeline();
        timelineRef.current
          // Run the animation
          .to(dotRef.current, { scale: 3, ease: "power4.in", duration: 0.5 })
          .to(dotRef.current, { opacity: 0, duration: 0.2 }, "-=0.1")
          .set(dotRef.current, { scale: 0, opacity: 0 })
          .to(dotRef.current, { scale: 1, opacity: 1, duration: 0.3, clearProps: "all" });
      }
      timelineRef.current.restart();
    }
  };

  useEffect(() => {
    if (hasLines) {
      runAnimation();
    }
    // Save the Ref as the ref value 'timelineRef.current' will likely have changed
    //   by the time this effect cleanup function runs.
    const currentTimeline = timelineRef.current;
    return function cleanup() {
      if (currentTimeline) {
        currentTimeline.kill();
      }
    };
  });

  return (
    <Link href="/wishlist" passHref>
      <Anchor $menuIsOpen={menuIsOpen}>
        <Icon />
        <DotContainer ref={dotRef} $hasProducts={hasLines}>
          <Dot />
        </DotContainer>
      </Anchor>
    </Link>
  );
};

export default WishlistButton;
