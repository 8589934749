import gsap from "gsap";
import { ScrollTrigger as _ScrollTrigger } from "gsap/ScrollTrigger";
import { Flip as _Flip } from "gsap/Flip";
import { SplitText as _SplitText } from "gsap/SplitText";
import { ScrollSmoother as _ScrollSmoother } from "gsap/ScrollSmoother";
import { TextPlugin as _TextPlugin } from "gsap/TextPlugin";
import { ScrollToPlugin as _ScrollToPlugin } from "gsap/ScrollToPlugin";

// Keep for Tree-Shaking
gsap.registerPlugin(
  _ScrollTrigger,
  _Flip,
  _SplitText,
  _ScrollSmoother,
  _TextPlugin,
  _ScrollToPlugin
);

export default gsap;
export const ScrollTrigger = _ScrollTrigger;
export const Flip = _Flip;
export const SplitText = _SplitText;
export const ScrollSmoother = _ScrollSmoother;
export const TextPlugin = _TextPlugin;
export const ScrollToPlugin = _ScrollToPlugin;
