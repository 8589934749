import { SVGProps } from "react";

type Props = SVGProps<SVGSVGElement> & {
  title?: string;
};

/**
 * Optimized with https://jakearchibald.github.io/svgomg/
 */
const SVGEcoFillSmall: React.FC<Props> = (props) => {
  const { title, ...rest } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      fillRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
      clipRule="evenodd"
      viewBox="0 0 18 18"
      {...rest}
    >
      <title>{title}</title>
      <path
        fill="#6c936f"
        fillRule="nonzero"
        d="M17.667 12.714c-1.389 4.004-6.215 3.936-10.127 3.936H3.781c4.49-3.166 6.986-6.146 8.381-9.637a7.4 7.4 0 0 0 .349-1.113 5.582 5.582 0 0 1 2.467.561 5.354 5.354 0 0 1 1.929 1.578c.504.666.836 1.437.968 2.25a4.984 4.984 0 0 1-.208 2.425Z"
      />
      <path
        fill="#6c936f"
        fillRule="nonzero"
        d="M7.313 0C5.495 0 3.761 1.274 3.158 2.945c-.401 1.1-.475 2.814-.459 4.516v8.672a20.056 20.056 0 0 1-2.624 1.464.224.224 0 0 0-.071.121.217.217 0 0 0 .117.237c.044.022.094.03.142.023a20.572 20.572 0 0 0 2.448-1.34l.156-.11a22.066 22.066 0 0 0 1.941-1.418c3.195-2.464 5.48-5.064 6.784-8.394C13.104 2.844 10.469 0 7.313 0Z"
      />
    </svg>
  );
};

export default SVGEcoFillSmall;
