import { memo, PropsWithChildren, SVGProps } from "react";

type Props = PropsWithChildren<SVGProps<SVGSVGElement>>;

/**
 * Optimized with https://jakearchibald.github.io/svgomg/
 */
const SVGFilters: React.FC<Props> = (props) => {
  const { children, ...rest } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 18 20"
      {...rest}
    >
      {children}
      <path
        fill="currentColor"
        d="M1 2.5a.5.5 0 0 0 0 1v-1ZM7 3h.5H7Zm10 .5a.5.5 0 0 0 0-1v1Zm-16 6a.5.5 0 0 0 0 1v-1Zm12 .5h.5-.5Zm4 7.5a.5.5 0 0 0 0-1v1ZM3 15v.5-.5Zm0 4v.5-.5Zm-2-2h.5H1Zm14-9v.5V8Zm2 2h.5-.5Zm-2 2v.5-.5ZM9 1v.5V1Zm0 4v.5V5ZM1 3.5h6v-1H1v1Zm10 0h6v-1h-6v1Zm-10 7h12v-1H1v1Zm4 7h12v-1H5v1Zm-2-2A1.5 1.5 0 0 1 4.5 17h1A2.5 2.5 0 0 0 3 14.5v1ZM4.5 17A1.5 1.5 0 0 1 3 18.5v1A2.5 2.5 0 0 0 5.5 17h-1ZM3 18.5A1.5 1.5 0 0 1 1.5 17h-1A2.5 2.5 0 0 0 3 19.5v-1ZM1.5 17A1.5 1.5 0 0 1 3 15.5v-1A2.5 2.5 0 0 0 .5 17h1ZM15 8.5a1.5 1.5 0 0 1 1.5 1.5h1A2.5 2.5 0 0 0 15 7.5v1Zm1.5 1.5a1.5 1.5 0 0 1-1.5 1.5v1a2.5 2.5 0 0 0 2.5-2.5h-1ZM15 11.5a1.5 1.5 0 0 1-1.5-1.5h-1a2.5 2.5 0 0 0 2.5 2.5v-1ZM13.5 10A1.5 1.5 0 0 1 15 8.5v-1a2.5 2.5 0 0 0-2.5 2.5h1ZM9 1.5A1.5 1.5 0 0 1 10.5 3h1A2.5 2.5 0 0 0 9 .5v1ZM10.5 3A1.5 1.5 0 0 1 9 4.5v1A2.5 2.5 0 0 0 11.5 3h-1ZM9 4.5A1.5 1.5 0 0 1 7.5 3h-1A2.5 2.5 0 0 0 9 5.5v-1ZM7.5 3A1.5 1.5 0 0 1 9 1.5v-1A2.5 2.5 0 0 0 6.5 3h1Z"
      />
    </svg>
  );
};

export default memo(SVGFilters);
