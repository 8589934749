import { SVGArrowRight } from "@/lib/svg";
import CallToAction from "@/components/Button";
import styled from "styled-components";
import { forwardRef } from "react";

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 2rem 2.5rem;
  gap: 0 0.5rem;

  &.animation-enter {
    opacity: 0;
  }
  &.animation-exit {
    opacity: 1;
  }
  &.animation-enter-active {
    opacity: 1;
  }
  &.animation-exit-active {
    opacity: 0;
  }
  &.animation-enter-active,
  &.animation-exit-active {
    transition: opacity 300ms;
  }
`;

export const Button = forwardRef<
  HTMLAnchorElement,
  { children: string; href?: string; target?: string }
>((props, ref) => (
  <CallToAction
    ref={ref}
    as="a"
    variant="primaryBoxed"
    {...props}
    style={{ alignSelf: "end" }}
    icon={<SVGArrowRight />}
  >
    {props.children}
  </CallToAction>
));

Button.displayName = "Button";
