import styled from "styled-components";

export const Anchor = styled.a<{ $desktopMenuOpen?: boolean }>`
  color: ${({ theme }) => theme.colors.black};
  width: 12rem;
  min-height: 38px;
  display: flex;
  justify-content: center;
  padding: 0 1rem;
  box-sizing: content-box;
  cursor: pointer;

  svg {
    // Required by Safari
    width: 100%;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    padding: 0;
    color: ${({ theme, $desktopMenuOpen }) =>
      $desktopMenuOpen ? theme.colors.white : theme.colors.black};
    transition: color 0.3s;
  }
`;
