import styled, { createGlobalStyle } from "styled-components";
import { myTheme } from "./theme";
import { pageMargins } from "./helpers";

export const GlobalStyles = createGlobalStyle`
    :root {
      --page-margin: 1rem;
      ${({ theme }) => theme.mediaQueries.tablet} {
        --page-margin: 1.5rem;
      }
      ${({ theme }) => theme.mediaQueries.laptop} {
        // WARNING: Every time you update the --page-margin consider updating 
        //   the media query 'min-width' of each carousel to avoid breaking the
        //   alignment of the cards. 
        --page-margin: 2.5rem;
      }
      
    }
    html,body,#__next {
      height: 100%;
    }
    body {
        font-family: ${myTheme.fontFamilies.primary}
    }
    * {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    //// Hide TEST div
    //#cookiebanner > div:first-child {
    //  opacity: 0.0 !important;
    //}
    #CybotCookiebotDialogBodyButtonDecline {
      display: none !important;
    }
`;

export const MainWrapper = styled.div`
  ${pageMargins};
`;
