import { Container } from "./styles";
import dynamic from "next/dynamic";
import { MainLayoutQuery } from "@/lib/sanity/queries";
const ExternalLink = dynamic(() => import("./ExternalLink"));
const InternalLink = dynamic(() => import("./InternalLink"));

type Props = {
  items?: MainLayoutQuery["header"]["navigation"]["items"];
};

const Menu: React.FC<Props> = (props) => {
  const { items } = props;
  if (!items?.length) return null;
  return (
    <Container>
      {items?.map((item, index) => {
        if (typeof item?._key !== "string") return null;
        switch (item?._type) {
          case "externalLink":
            return (
              <ExternalLink
                key={item._key}
                {...item}
                variant={index === 0 ? "button" : "link"}
              />
            );
          case "internalLink":
            return (
              <InternalLink
                key={item._key}
                {...item}
                variant={index === 0 ? "button" : "link"}
              />
            );
          default:
            return null;
        }
      })}
    </Container>
  );
};

export default Menu;
