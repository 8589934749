import React from "react";
import { SVGArrowRight } from "@/lib/svg";
import CallToAction from "@/components/Button";
import styled from "styled-components";

export const Container = styled.nav`
  display: flex;
  flex-direction: column;
  gap: 2.5rem;

  &:not(:last-of-type) {
    padding-bottom: 2.5rem;
    border-bottom: solid 1px;
  }
`;

export const Anchor = styled.a`
  color: ${({ theme }) => theme.colors.white};
  text-decoration: none;
  font-size: 1.25rem;
  line-height: 1.2;
`;

export const Button: React.FC<{ children: string; href?: string; target?: string }> = (
  props
) => (
  <CallToAction
    as="a"
    variant="secondaryUnboxedNegative"
    icon={<SVGArrowRight />}
    style={{ paddingLeft: 0, paddingRight: 0, fontWeight: 800 }}
    {...props}
  >
    {props.children}
  </CallToAction>
);
