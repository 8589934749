// Only queries shared by multiple components

import { groq } from "next-sanity";
import { z } from "zod";
import { sanityClient } from "./server";

export const settingsQuery = groq`*[_id == "siteSettings"][0] {
  ...,
  seo {
    "metaTitle": coalesce(metaTitle[$locale], metaTitle[$defaultLocale]),
    "metaDesc": coalesce(metaDesc[$locale], metaDesc[$defaultLocale]),
    "shareTitle": coalesce(shareTitle[$locale], shareTitle[$defaultLocale]),
    "shareDesc": coalesce(shareDesc[$locale], shareDesc[$defaultLocale]),
    shareGraphic {
      asset->{
        ...,
        "alt": coalesce(alt[$locale], alt[$defaultLocale])
      }
    }
  },
}
`;

export const verificationEmailQuery = groq`*[_id == "settings"][0] { emailsAfterAccountVerification }`;

export const mainLayoutQuery = groq`{
  "header": *[_id == "header"][0] {
    ...,
    navigation {
      items[] {
        ...,
        _type == "internalLink" => {
          "title": coalesce(title[$locale], title[$defaultLocale]),
          page->{ _type, "slug": slug.current },
          params,
        },
        _type == "externalLink" => {
          "title": coalesce(title[$locale], title[$defaultLocale])
        },
        _type == "headerTab" => {
          ...,
          "title": coalesce(title[$locale], title[$defaultLocale]),
          content[] {
            ...,
            _type == "externalLink" => {
              "title": coalesce(title[$locale], title[$defaultLocale])
            },
            _type == "internalLink" => {
              "title": coalesce(title[$locale], title[$defaultLocale]),
               page->{ _type, "slug": slug.current },
               params,
            },
            _type == "menu" => {
              items[] {
                ...,
                _type == "externalLink" => {
                  "title": coalesce(title[$locale], title[$defaultLocale])
                },
                _type == "internalLink" => {
                  "title": coalesce(title[$locale], title[$defaultLocale]),
                  page->{ _type, "slug": slug.current },
                  params,
                }
              }
            }
          }
        }
      }
    }
  },
  "footer": *[_id == "footer"][0] {
    ...,
    "content": coalesce(content[$locale], content[$defaultLocale]),
    navigation {
      ...,
      items[] {
        ...,
        _type == "externalLink" => {
          ...,
          "title": coalesce(title[$locale], title[$defaultLocale]),
        },
        _type == "internalLink" => {
          "title": coalesce(title[$locale], title[$defaultLocale]),
          page->{ _type, "slug": slug.current },
          params,
        },
        _type == "linkGroup" => {
          "title": coalesce(title[$locale], title[$defaultLocale]),
          items[] {
            ...,
            "title": coalesce(title[$locale], title[$defaultLocale]),
            _type == "internalLink" => {
              page->{ _type, "slug": slug.current },
              params,
            }
          }
        }
      }
    }
  }    
}`;

export const mainLayoutQuerySchema = z.object({
  header: z.object({
    navigation: z.object({
      items: z.array(
        z.discriminatedUnion("_type", [
          z.object({
            _key: z.string(),
            _type: z.literal("internalLink"),
            title: z.string(),
            params: z.string().nullable(),
            page: z.object({ _type: z.string(), slug: z.string().nullable() }),
          }),
          z.object({
            _key: z.string(),
            _type: z.literal("externalLink"),
            title: z.string(),
            url: z.string(),
            blank: z.boolean(),
          }),
          z.object({
            _key: z.string(),
            _type: z.literal("headerTab"),
            title: z.string(),
            content: z.array(
              z.discriminatedUnion("_type", [
                z.object({
                  _key: z.string(),
                  _type: z.literal("internalLink"),
                  title: z.string(),
                  params: z.string().nullable(),
                  page: z.object({
                    _type: z.string(),
                    slug: z.string().nullable(),
                  }),
                }),
                z.object({
                  _key: z.string(),
                  _type: z.literal("externalLink"),
                  title: z.string(),
                  url: z.string(),
                  blank: z.boolean(),
                }),
                z.object({
                  _key: z.string(),
                  _type: z.literal("menu"),
                  items: z.array(
                    z.discriminatedUnion("_type", [
                      z.object({
                        _key: z.string(),
                        _type: z.literal("internalLink"),
                        title: z.string(),
                        params: z.string().nullable(),
                        page: z.object({
                          _type: z.string(),
                          slug: z.string().nullable(),
                        }),
                      }),
                      z.object({
                        _key: z.string(),
                        _type: z.literal("externalLink"),
                        title: z.string(),
                        url: z.string(),
                        blank: z.boolean(),
                      }),
                    ])
                  ),
                }),
              ])
            ),
          }),
        ])
      ),
    }),
  }),
  footer: z.object({
    content: z.any(),
    navigation: z.object({
      items: z.array(
        z.discriminatedUnion("_type", [
          z.object({
            _key: z.string(),
            _type: z.literal("internalLink"),
            title: z.string(),
            params: z.string().nullable(),
            page: z.object({ _type: z.string(), slug: z.string().nullable() }),
          }),
          z.object({
            _key: z.string(),
            _type: z.literal("externalLink"),
            title: z.string(),
            url: z.string(),
            blank: z.boolean(),
          }),
          z.object({
            _key: z.string(),
            _type: z.literal("linkGroup"),
            title: z.string(),
            items: z.array(
              z.object({
                _key: z.string(),
                _type: z.literal("internalLink"),
                title: z.string(),
                params: z.string().nullable(),
                page: z.object({
                  _type: z.string(),
                  slug: z.string().nullable(),
                }),
              })
            ),
          }),
          z.object({
            _key: z.string(),
            _type: z.literal("socialLinks"),
            youtube: z.string(),
            linkedin: z.string(),
          }),
        ])
      ),
    }),
  }),
});

export type MainLayoutQuery = z.infer<typeof mainLayoutQuerySchema>;

export const getMainLayoutQuery = async (params: {
  locale: string;
  defaultLocale: string;
}) => {
  const res = await sanityClient.fetch(mainLayoutQuery, params);
  return mainLayoutQuerySchema.parse(res);
};

export const seoQuerySchema = z.object({
  metaTitle: z.string().nullable(),
  metaDesc: z.string().nullable(),
  shareTitle: z.string().nullable(),
  shareDesc: z.string().nullable(),
  shareGraphic: z.object({
    asset: z.object({
      url: z.string(),
      metadata: z
        .object({
          dimensions: z.object({
            width: z.number(),
            height: z.number(),
          }),
        })
    }).nullable()
  }).nullable(),
}).nullable();

export const marketingPageQuery = groq`
*[_type == "marketingPage" && slug.current == $slug] {
  ...,
  seo {
    "metaTitle": coalesce(metaTitle[$locale], metaTitle[$defaultLocale]),
    "metaDesc": coalesce(metaDesc[$locale], metaDesc[$defaultLocale]),
    "shareTitle": coalesce(shareTitle[$locale], shareTitle[$defaultLocale]),
    "shareDesc": coalesce(shareDesc[$locale], shareDesc[$defaultLocale]),
    shareGraphic {
      asset->{
        ...,
        "alt": coalesce(alt[$locale], alt[$defaultLocale])
      }
    }
  },
  goBackLinks[] {
    ...,
    _type == "internalLink" => {
      "title": coalesce(title[$locale], title[$defaultLocale]),
      page->{ _type, "slug": slug.current },
      params,
    },
    _type == "externalLink" => {
      "title": coalesce(title[$locale], title[$defaultLocale]),
      url
    },
  },
  pageBuilder[] {
    ...,
    _type == "cardCarousel" => {
      _type,
      _key,
      variant,
      variant == "people" => {
        "cards": peopleCards[] {
          _key,
          variant,
          name,
          "role": coalesce(role[$locale], role[$defaultLocale]),
          "excerpt": coalesce(excerpt[$locale], excerpt[$defaultLocale]),
          image {
            ...,
            asset->{
              ...,
              "alt": coalesce(alt[$locale], alt[$defaultLocale])
            }
          },
        }
      },
      variant == "text" => {
        "cards": textCards[] {
          _key,
          variant,
          "title": coalesce(title[$locale], title[$defaultLocale]),
          "subtitle": coalesce(subtitle[$locale], subtitle[$defaultLocale]),
          datetime,
          externalLink {
            "title": coalesce(title[$locale], title[$defaultLocale]),
            url,
            blank
          },
        }
      },
      variant == "gallery" => {
        "cards": galleryCards[] {
          _key,
          variant,
          "description": coalesce(description[$locale], description[$defaultLocale]),
          image {
            ...,
            asset->{
              ...,
              "alt": coalesce(alt[$locale], alt[$defaultLocale])
            }
          },
        }
      },
      variant == "contest" => {
        "cards": contestCards[] {
          _key,
          variant,
          contestUrl,
          "title": coalesce(title[$locale], title[$defaultLocale]),
          endsOn,
          "prize": coalesce(prize[$locale], prize[$defaultLocale]),

          image {
            ...,
            asset->{
              ...,
              "alt": coalesce(alt[$locale], alt[$defaultLocale])
            }
          },
        }
      },
    //  variant == "contact" => {
    //    "cards" : contactCards[] {
    //    _key,
    //    variant,
    //    "heading": coalesce(heading[$locale], heading[$defaultLocale]),
    //  "subheading": coalesce(subheading[$locale], subheading[$defaultLocale]),
    //  address,
    //  email,
    //  phone,
    //  image {
    //        ...,
    //        asset->{
    //          ...,
    //          "alt": coalesce(alt[$locale], alt[$defaultLocale])
    //        }
    //      },
    //  }
    //  },

    },
    _type == "carousel" => {
      items[] { 
        _key,
        "title": coalesce(title[$locale], title[$defaultLocale]),
        "subtitle": coalesce(subtitle[$locale], subtitle[$defaultLocale]),
        link {
          "title": coalesce(title[$locale], title[$defaultLocale]),
          page->{ _type, "slug": slug.current }
        },
        "image": image {
          ...,
          asset->
        }
      }
    },
    _type == "form" => {
      ...,
      "heading": coalesce(heading[$locale], heading[$defaultLocale]),
      "buttonText": coalesce(buttonText[$locale], buttonText[$defaultLocale]),
      "shortText": coalesce(shortText[$locale], shortText[$defaultLocale]),
      fields[] {
        ...,
        "placeholder": coalesce(placeholder[$locale], placeholder[$defaultLocale]),
        "label": coalesce(label[$locale], label[$defaultLocale]),
        items[]{
          ...,
          "value": coalesce(value[$locale], value[$defaultLocale])
        },
      }
    },
    _type == "sectionWithIllustrations" => {
      "heading": coalesce(heading[$locale], heading[$defaultLocale]),
      "excerpt": coalesce(excerpt[$locale], excerpt[$defaultLocale]),
      "excerptPortable": coalesce(excerptPortable[$locale], excerptPortable[$defaultLocale]),
      image {
        ...,
        asset->{
          ...,
          "alt": coalesce(alt[$locale], alt[$defaultLocale])
        }
      },
      buttons[] {
          ...,
          _type == "callToAction" => {
            action,
            icon,
            action == "internalLink" => {
              internalLink {
                "title": coalesce(title[$locale], title[$defaultLocale]),
                page->{ _type, "slug": slug.current },
                params,
              },
            },
            action == "externalLink" => {
              externalLink {
                "title": coalesce(title[$locale], title[$defaultLocale]),
                url,
                blank
              },
            },
            action == "downloadLink" => {
              downloadLink {
                "title": coalesce(title[$locale], title[$defaultLocale]),
                "file": coalesce(file[$locale], file[$defaultLocale]) {
                  asset->{ url }
                }
              },
            },
            action == "productSearch" => {
              productSearch {
                "title": coalesce(title[$locale], title[$defaultLocale]),
                page->{ _type, "slug": slug.current }
              }
            }
          }
        },
      illustrations[] {
        ...,
        "heading": coalesce(heading[$locale], heading[$defaultLocale]),
        "excerpt": coalesce(excerpt[$locale], excerpt[$defaultLocale]),
        "excerptPortable": coalesce(excerptPortable[$locale], excerptPortable[$defaultLocale]),
        buttons[] {
          ...,
          _type == "callToAction" => {
            action,
            icon,
            action == "internalLink" => {
              internalLink {
                "title": coalesce(title[$locale], title[$defaultLocale]),
                page->{ _type, "slug": slug.current },
                params,
              },
            },
            action == "externalLink" => {
              externalLink {
                "title": coalesce(title[$locale], title[$defaultLocale]),
                url,
                blank
              },
            },
            action == "downloadLink" => {
              downloadLink {
                "title": coalesce(title[$locale], title[$defaultLocale]),
                "file": coalesce(file[$locale], file[$defaultLocale]) {
                  asset->{ url }
                }
              },
            },
            action == "productSearch" => {
              productSearch {
                "title": coalesce(title[$locale], title[$defaultLocale]),
                page->{ _type, "slug": slug.current }
              }
            }
          }
        },
        image {
          ...,
          asset->{
            ...,
            "alt": coalesce(alt[$locale], alt[$defaultLocale])
          }
        }
      }
    },
    _type == "textWithCards" => {
      "heading": coalesce(heading[$locale], heading[$defaultLocale]),
      "body": coalesce(body[$locale], body[$defaultLocale]),
      buttons[] {
        ...,
        _type == "callToAction" => {
          action,
          icon,
          action == "internalLink" => {
            internalLink {
              "title": coalesce(title[$locale], title[$defaultLocale]),
              page->{ _type, "slug": slug.current },
              params,
            },
          },
          action == "externalLink" => {
            externalLink {
              "title": coalesce(title[$locale], title[$defaultLocale]),
              url,
              blank
            },
          },
          action == "downloadLink" => {
            downloadLink {
              "title": coalesce(title[$locale], title[$defaultLocale]),
              "file": coalesce(file[$locale], file[$defaultLocale]) {
                asset->{ url }
              }
            },
          },
          action == "productSearch" => {
            productSearch {
              "title": coalesce(title[$locale], title[$defaultLocale]),
              page->{ _type, "slug": slug.current }
            }
          }
        }
      },
      cards[] {
        _key,
        "title": coalesce(title[$locale], title[$defaultLocale]),
        "subtitle": coalesce(subtitle[$locale], subtitle[$defaultLocale]),
        image {
          asset->{ url }
        },
        links[] {
          _type,
          _key,
          _type == "internalLink" => {
            "title": coalesce(title[$locale], title[$defaultLocale]),
            page->{ _type, "slug": slug.current },
            params,
          },
          _type == "externalLink" => {
            "title": coalesce(title[$locale], title[$defaultLocale]),
            url,
            blank
          }
        } 
      }
    },
    _type == "textWithImages" => {
      "heading": coalesce(heading[$locale], heading[$defaultLocale]),
      "body": coalesce(body[$locale], body[$defaultLocale]),
      buttons[] {
        ...,
        _type == "callToAction" => {
          details,
          action,
          icon,
          action == "internalLink" => {
            internalLink {
              "title": coalesce(title[$locale], title[$defaultLocale]),
              page->{ _type, "slug": slug.current },
              params,
            },
          },
          action == "externalLink" => {
            externalLink {
              "title": coalesce(title[$locale], title[$defaultLocale]),
              url,
              blank
            },
          },
          action == "downloadLink" => {
            downloadLink {
              "title": coalesce(title[$locale], title[$defaultLocale]),
              "file": coalesce(file[$locale], file[$defaultLocale]) {
                asset->{ url }
              }
            },
          },
          action == "productSearch" => {
            productSearch {
              "title": coalesce(title[$locale], title[$defaultLocale]),
              page->{ _type, "slug": slug.current }
            }
          }
        }
      },      
      images[]->{
        _key,     
        "heading": coalesce(heading[$locale], heading[$defaultLocale]),
        image {
          asset->{ 
            url,
            ...metadata.dimensions {
              aspectRatio
            }
          }
        },
        hotspots[] {
          _key,
          ...,
          isExternal,
          internalLink {
            page->{ _type, "slug": slug.current },
            params,
          },
          externalLink {
            url,
            blank
          }
        }
      }
    },
    _type == "hero" => {
      ...,
      "heading": coalesce(heading[$locale], heading[$defaultLocale]),
      "excerpt": coalesce(excerpt[$locale], excerpt[$defaultLocale]),
      media[] {
        _type,
        _type == "image" => {
          asset->{ url }
        },
        _type == "video" => {
          autoplay,
          loop,
          file {
            asset->{ url }
          },
          posterImage {
            asset->{ url }
          }
        }
      },
      // Also used in "bannerQuote"      
      buttons[] {
        ...,
        _type == "callToAction" => {
          action,
          icon,
          action == "internalLink" => {
            internalLink {
              "title": coalesce(title[$locale], title[$defaultLocale]),
              page->{ _type, "slug": slug.current },
              params,
            },
          },
          action == "externalLink" => {
            externalLink {
              "title": coalesce(title[$locale], title[$defaultLocale]),
              url,
              blank
            },
          },
          action == "downloadLink" => {
            downloadLink {
              "title": coalesce(title[$locale], title[$defaultLocale]),
              "file": coalesce(file[$locale], file[$defaultLocale]) {
                asset->{ url }
              }
            },
          },
          action == "productSearch" => {
            productSearch {
              "title": coalesce(title[$locale], title[$defaultLocale]),
              page->{ _type, "slug": slug.current }
            }
          }
        }
      },
      internalLink {
        "title": coalesce(title[$locale], title[$defaultLocale]),
        page->{ _type, "slug": slug.current },
        params,
      }
    },
    _type == "highlights" => {
      highlights[]{
        "heading": coalesce(heading[$locale], heading[$defaultLocale]),
        "excerpt": coalesce(excerpt[$locale], excerpt[$defaultLocale]),
        textPosition,
        image {
          "alt": coalesce(alt[$locale], alt[$defaultLocale]),
          asset->
        }
      }
    },
    _type == "locationsBlock" => {
      "cards": locationsBlock->locations[]{
        ...,
        "variant": "location",
        "address": coalesce(address[$locale], address[$defaultLocale]),
        "title": coalesce(title[$locale], title[$defaultLocale]),
        "subtitle": coalesce(subtitle[$locale], subtitle[$defaultLocale]),
        image {
          "alt": coalesce(alt[$locale], alt[$defaultLocale]),
          asset->
        },
        "link": link[0] {
          "title": coalesce(title[$locale], title[$defaultLocale]),
          _type == "externalLink" => {
            url,
            blank
          },
          _type == "internalLink" => {
            page->{ _type, "slug": slug.current },
            params,
          },
        }
      }
    },
    _type == "numberedParagraphs" => {
      ...,
      "title": coalesce(title[$locale], title[$defaultLocale]),
      paragraphs[] {
        ...,
        "title": coalesce(title[$locale], title[$defaultLocale]),
        "body":  coalesce(body[$locale], body[$defaultLocale])
      }
    },
    _type == "paragraph" => {
      "heading": coalesce(heading[$locale], heading[$defaultLocale]),
      "excerpt": coalesce(excerpt[$locale], excerpt[$defaultLocale]),
      buttons[] {
        ...,
        _type == "internalLink" => {
          "title": coalesce(title[$locale], title[$defaultLocale]),
          page->{ _type, "slug": slug.current },
          params,
        },
        _type == "externalLink" => {
          "title": coalesce(title[$locale], title[$defaultLocale])
        },
      },
    },
    _type == "textWithIllustration" => {
      "heading": coalesce(heading[$locale], heading[$defaultLocale]),
      "excerpt": coalesce(excerpt[$locale], excerpt[$defaultLocale]),
      buttons[] {
        ...,
        _type == "internalLink" => {
          "title": coalesce(title[$locale], title[$defaultLocale]),
          page->{ _type, "slug": slug.current },
          params,
        },
        _type == "externalLink" => {
          "title": coalesce(title[$locale], title[$defaultLocale])
        },
      },
      image {
        ...,
        "alt": coalesce(alt[$locale], alt[$defaultLocale]),
        asset->,
        "heading": coalesce(heading[$locale], heading[$defaultLocale]),
        "excerpt": coalesce(excerpt[$locale], excerpt[$defaultLocale]),
        buttons[] {
          ...,
          _type == "internalLink" => {
            "title": coalesce(title[$locale], title[$defaultLocale]),
            page->{ _type, "slug": slug.current },
            params,
          },
          _type == "externalLink" => {
            "title": coalesce(title[$locale], title[$defaultLocale])
          },
        },
      }
    },
    _type == "subTextWithIllustration" => {
      "heading": coalesce(heading[$locale], heading[$defaultLocale]),
      "excerpt": coalesce(excerpt[$locale], excerpt[$defaultLocale]),
      buttons[] {
        ...,
        _type == "internalLink" => {
          "title": coalesce(title[$locale], title[$defaultLocale]),
          page->{ _type, "slug": slug.current },
          params,
        },
        _type == "externalLink" => {
          "title": coalesce(title[$locale], title[$defaultLocale])
        },
        _type == "mailtoLink" => {
          "title": coalesce(title[$locale], title[$defaultLocale])
        },
      },
      image {
        ...,
        "alt": coalesce(alt[$locale], alt[$defaultLocale]),
        asset->,
        "heading": coalesce(heading[$locale], heading[$defaultLocale]),
        "excerpt": coalesce(excerpt[$locale], excerpt[$defaultLocale]),
        buttons[] {
          ...,
          _type == "internalLink" => {
            "title": coalesce(title[$locale], title[$defaultLocale]),
            page->{ _type, "slug": slug.current },
            params,
          },
          _type == "externalLink" => {
            "title": coalesce(title[$locale], title[$defaultLocale])
          },
        },
      }
    },
    _type == "launch" => {
      _key,
      variant,
      variant == "single" => {
        "single": SingleLaunch {
          "label": coalesce(label[$locale], label[$defaultLocale]),
          "heading": coalesce(heading[$locale], heading[$defaultLocale]),
          "excerpt": coalesce(excerpt[$locale], excerpt[$defaultLocale]),
          buttons[] {
            ...,
            _type == "internalLink" => {
              "title": coalesce(title[$locale], title[$defaultLocale]),
              page->{ _type, "slug": slug.current },
              params,
            },
            _type == "externalLink" => {
              "title": coalesce(title[$locale], title[$defaultLocale])
            },
          },
          image {
            ...,
            "alt": coalesce(alt[$locale], alt[$defaultLocale]),
            asset->
          }
        }
      },
      variant == "double" => {
        "double": DoubleLaunch[] {
          _key,
          "label": coalesce(label[$locale], label[$defaultLocale]),
          "heading": coalesce(heading[$locale], heading[$defaultLocale]),
          "excerpt": coalesce(excerpt[$locale], excerpt[$defaultLocale]),
          buttons[] {
            ...,
            _type == "internalLink" => {
              "title": coalesce(title[$locale], title[$defaultLocale]),
              page->{ _type, "slug": slug.current },
              params,
            },
            _type == "externalLink" => {
              "title": coalesce(title[$locale], title[$defaultLocale])
            },
          },
          image {
            ...,
            "alt": coalesce(alt[$locale], alt[$defaultLocale]),
            asset->
          }
        }
      },
    },
    _type == "textWithBulletPoints" => {
      "heading": coalesce(heading[$locale], heading[$defaultLocale]),
      "excerpt": coalesce(excerpt[$locale], excerpt[$defaultLocale]),
      buttons[] {
        ...,
        _type == "internalLink" => {
          "title": coalesce(title[$locale], title[$defaultLocale]),
          page->{ _type, "slug": slug.current },
          params,
        },
        _type == "externalLink" => {
          "title": coalesce(title[$locale], title[$defaultLocale])
        },
        _type == "callToAction" => {
          action,
          icon,
          action == "internalLink" => {
            internalLink {
              "title": coalesce(title[$locale], title[$defaultLocale]),
              page->{ _type, "slug": slug.current },
              params,
            },
          },
          action == "externalLink" => {
            externalLink {
              "title": coalesce(title[$locale], title[$defaultLocale]),
              url,
              blank
            },
          },
          action == "downloadLink" => {
            downloadLink {
              "title": coalesce(title[$locale], title[$defaultLocale]),
              "file": coalesce(file[$locale], file[$defaultLocale]) {
                asset->{ url }
              }
            },
          },
          action == "productSearch" => {
            productSearch {
              "title": coalesce(title[$locale], title[$defaultLocale]),
              page->{ _type, "slug": slug.current }
            }
          }
        }
      },
      bulletPoints[] {
        ...,
        "heading": coalesce(heading[$locale], heading[$defaultLocale]),
        "paragraph": coalesce(paragraph[$locale], paragraph[$defaultLocale]),
      }
    },
    _type == "textWithLabeledImages" => {
      "heading": coalesce(heading[$locale], heading[$defaultLocale]),
      "excerpt": coalesce(excerpt[$locale], excerpt[$defaultLocale]),
      buttons[] {
        ...,
        action,
        icon,
        action == "internalLink" => {
          internalLink {
            "title": coalesce(title[$locale], title[$defaultLocale]),
            page->{ _type, "slug": slug.current },
            params,
          },
        },
        action == "externalLink" => {
          externalLink {
            "title": coalesce(title[$locale], title[$defaultLocale]),
            url,
            blank
          },
        },
        action == "downloadLink" => {
          downloadLink {
            "title": coalesce(title[$locale], title[$defaultLocale]),
            "file": coalesce(file[$locale], file[$defaultLocale]) {
              asset->{ url }
            }
          },
        },
        action == "productSearch" => {
          productSearch {
            "title": coalesce(title[$locale], title[$defaultLocale]),
            page->{ _type, "slug": slug.current }
          }
        }
      },
      labeledImages[] {
        ...,
        "label": coalesce(label[$locale], label[$defaultLocale]),
        image {
          asset->{
            url,
            ...metadata.dimensions{
              width,
              height
            }
          }
        },
        link {
          "internalLink": {
            "title": coalesce(title[$locale], title[$defaultLocale]),
            page->{ _type, "slug": slug.current },
            params,
          }
        }
      }
    },
    _type == "textPodiumWithImage" => {
      "heading": coalesce(heading[$locale], heading[$defaultLocale]),
      "excerpt": coalesce(excerpt[$locale], excerpt[$defaultLocale]),
      buttons[] {
        ...,
        _type == "internalLink" => {
          "title": coalesce(title[$locale], title[$defaultLocale]),
          page->{ _type, "slug": slug.current },
          params,
        },
        _type == "externalLink" => {
          "title": coalesce(title[$locale], title[$defaultLocale])
        },
        _type == "callToAction" => {
          action,
          icon,
          action == "internalLink" => {
            internalLink {
              "title": coalesce(title[$locale], title[$defaultLocale]),
              page->{ _type, "slug": slug.current },
              params,
            },
          },
          action == "externalLink" => {
            externalLink {
              "title": coalesce(title[$locale], title[$defaultLocale]),
              url,
              blank
            },
          },
          action == "downloadLink" => {
            downloadLink {
              "title": coalesce(title[$locale], title[$defaultLocale]),
              "file": coalesce(file[$locale], file[$defaultLocale]) {
                asset->{ url }
              }
            },
          },
          action == "productSearch" => {
            productSearch {
              "title": coalesce(title[$locale], title[$defaultLocale]),
              page->{ _type, "slug": slug.current }
            }
          }
        }
      },
      image {
        ...,
        "alt": coalesce(alt[$locale], alt[$defaultLocale]),
        asset->
      }
    }, 
    _type == "richText" => {
      _key,
      "body": coalesce(content[$locale], content[$defaultLocale])[] {
        _type == "files" => {
          _key,
          _type,
          files[] {
            _type,
            _key,
            name,
            description,
            asset->
          }
        },
        _type == "block" => {
          ...
        }
      }
    },
    _type == "accordion" => {
      ...,
      items[] {
        _key,
         "title": coalesce(title[$locale], title[$defaultLocale]),
         "content": coalesce(content[$locale], content[$defaultLocale])[] {
          _type == "files" => {
            _key,
            _type,
            files[] {
              _type,
              _key,
              name,
              description,
              asset->
            }
          },
          _type == "block" => {
            ...
          }    
        } 
      }
    },
    _type == "bannerQuote" => {
      ...,
      "quote": coalesce(quote[$locale], quote[$defaultLocale]),
      "author": coalesce(author[$locale], author[$defaultLocale]),
      "excerpt": coalesce(excerpt[$locale], excerpt[$defaultLocale]),
      
      // Copied from "hero"      
      buttons[] {
        ...,
        _type == "callToAction" => {
          action,
          icon,
          action == "internalLink" => {
            internalLink {
              "title": coalesce(title[$locale], title[$defaultLocale]),
              page->{ _type, "slug": slug.current },
              params,
            },
          },
          action == "externalLink" => {
            externalLink {
              "title": coalesce(title[$locale], title[$defaultLocale]),
              url,
              blank
            },
          },
          action == "downloadLink" => {
            downloadLink {
              "title": coalesce(title[$locale], title[$defaultLocale]),
              "file": coalesce(file[$locale], file[$defaultLocale]) {
                asset->{ url }
              }
            },
          },
          action == "productSearch" => {
            productSearch {
              "title": coalesce(title[$locale], title[$defaultLocale]),
              page->{ _type, "slug": slug.current }
            }
          }
        }
      },
    },
    _type == "contacts" => {
      ...,
      "heading": coalesce(heading[$locale], heading[$defaultLocale]),
      "subheading": coalesce(subheading[$locale], subheading[$defaultLocale]),
      "address": coalesce(address[$locale], address[$defaultLocale]),
      "email": email,
      "phone": phone
    },
    _type == "spacer" => {
      ...,
      size
    },
    _type == "relatedArticles" => {
      _id,
      articles[]->{
        thumbnail {
          asset->
        },
        "heading": coalesce(heading[$locale], heading[$defaultLocale]),
        "excerpt": coalesce(excerpt[$locale], excerpt[$defaultLocale]),
        "slug": slug.current,
        "category": category->{"title": coalesce(category[$locale], category[$defaultLocale])}.title,
        releaseDate

      }
    },
    _type == "featuredProducts" => {
      _id,
      "title": coalesce(title[$locale], title[$defaultLocale]),
      rules[] {
        "key": attribute._ref,
        value
      },
    },
    _type == "heroHomepage" => {
      _id,
      "headingLineOne": coalesce(headingLineOne[$locale], headingLineOne[$defaultLocale]),
      "headingLineTwo": coalesce(headingLineTwo[$locale], headingLineTwo[$defaultLocale]),
      "excerpt": coalesce(excerpt[$locale], excerpt[$defaultLocale]),
      mobileImage {
        ...,
        asset->
      },
      tabletImage {
        ...,
        asset->
      },
      laptopImage {
        ...,
        asset->
      },
      internalLink  {
        "title": coalesce(title[$locale], title[$defaultLocale]),
        page->{ _type, "slug": slug.current },
        params,
      }
    }
  }
}
`;

export const corporatePageQuery = groq`
*[_type == "corporatePage" && slug.current == $slug] {
  ...,
  seo {
    "metaTitle": coalesce(metaTitle[$locale], metaTitle[$defaultLocale]),
    "metaDesc": coalesce(metaDesc[$locale], metaDesc[$defaultLocale]),
    "shareTitle": coalesce(shareTitle[$locale], shareTitle[$defaultLocale]),
    "shareDesc": coalesce(shareDesc[$locale], shareDesc[$defaultLocale]),
    shareGraphic {
      "alt": coalesce(alt[$locale], alt[$defaultLocale]),
      asset->
    }
  },
  paragraphIndex[] {
    ...,
    "heading": coalesce(heading[$locale], heading[$defaultLocale]),
    "slug": slug.current,
  },
  pageBuilder[] {
    ...,
    _type == "button" => {
      buttons[] {
        ...,
        // TODO: remove
        _type == "internalLink" => {
          "title": coalesce(title[$locale], title[$defaultLocale]),
          page->{ _type, "slug": slug.current },
          params,
        },
        // TODO: remove
        _type == "externalLink" => {
          "title": coalesce(title[$locale], title[$defaultLocale])
        },
        _type == "callToAction" => {
          action,
          icon,
          action == "internalLink" => {
            internalLink {
              "title": coalesce(title[$locale], title[$defaultLocale]),
              page->{ _type, "slug": slug.current },
              params,
            },
          },
          action == "externalLink" => {
            externalLink {
              "title": coalesce(title[$locale], title[$defaultLocale]),
              url,
              blank
            },
          },
          action == "downloadLink" => {
            downloadLink {
              "title": coalesce(title[$locale], title[$defaultLocale]),
              "file": coalesce(file[$locale], file[$defaultLocale]) {
                asset->{ url }
              }
            },
          },
          action == "productSearch" => {
            productSearch {
              "title": coalesce(title[$locale], title[$defaultLocale]),
                page->{ _type, "slug": slug.current }
            }
          }
        }
      },
    },
    _type == "paragraphTitle" => {
      "heading": coalesce(heading[$locale], heading[$defaultLocale]),
      "slug": slug.current,
    },
    _type == "richText" => {
      _key,
      "body": coalesce(content[$locale], content[$defaultLocale])[] {
        ...,
        _type == "files" => {
          _key,
          _type,
          files[] {
            _type,
            _key,
            name,
            description,
            asset->
          }
        },
        _type == "block" => {
          ...,
          children[] {
            ...,
            _type == "richTextImage" => {
              image {
                asset->{
                  url,
                }
              }
            },
          },
          markDefs[] {
            ...,
            _type == "internalLink" => {
              "slug": @.reference->slug.current,
              params,
            },
            _type == "externalLink" => {
              url,
              blank
            }
          }
        }
      }
    },
    _type == "accordion" => {
      ...,
      items[] {
        _key,
          "title": coalesce(title[$locale], title[$defaultLocale]),
          "content": coalesce(content[$locale], content[$defaultLocale])[] {
          _type == "files" => {
            _key,
            _type,
            files[] {
              _type,
              _key,
              name,
              description,
              asset->
            }
          },
          _type == "block" => {
            ...,
            children[] {
              ...,
              _type == "richTextImage" => {
                image {
                  asset->{
                    url,
                  }
                }
              },
            },
            markDefs[] {
              _type,
              _key,
              _type == "internalLink" => {
                "slug": @.reference->slug.current,
                params,
              },
              _type == "externalLink" => {
                url,
                blank
              }
            }
          },
        } 
      }
    },
    _type == "spacer" => {
      ...,
      size
    }
  }
}
`;
