import { resetButton } from "@/lib/styles";
import { SVGWishlist } from "@/lib/svg";
import styled, { keyframes } from "styled-components";
import SVGWishlistDot from "@/lib/svg/SVGWishlistDot";

export const Icon = styled(SVGWishlist)`
  width: 20px;
  transform-origin: center;
`;

export const DotContainer = styled.div<{ $hasProducts: boolean }>`
  position: absolute;
  right: -1px;
  top: 5px;
  width: 10px;
  height: 12px;
  transform-origin: center center;
  opacity: ${({ theme, $hasProducts }) =>
    $hasProducts ? 1 : 0 };
`

export const Dot = styled(SVGWishlistDot)`
  width: 10px;
`;

export const Anchor = styled.a<{ $menuIsOpen: boolean }>`
  position: relative;
  display: flex;
  color: ${({ theme, $menuIsOpen }) =>
    $menuIsOpen ? theme.colors.white : theme.colors.black};

  circle {
    opacity: 1;
    stroke: ${({ theme, $menuIsOpen }) =>
      $menuIsOpen ? theme.colors.black : theme.colors.white};
    transition: stroke 0.3s;
  }
`;
