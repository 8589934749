import styled from "styled-components";

export const Container = styled.footer`
  background-color: ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: baseline;
  padding: 2.5rem 1.25rem;

  ${({ theme }) => theme.mediaQueries.tablet} {
    padding: 2.5rem 1.5rem;
    flex-direction: row;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    justify-content: space-between;
    padding: 2.5rem;
  }
`;
