import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

export type JobsFilter = {
  id: string;
  values: string[];
  selectedValues: string[];
};

export const jobsFilterAdapter = createEntityAdapter<JobsFilter>({
  // Assume IDs are stored in a field other than `filter.id`
  selectId: (filter) => filter.id,
  // Keep the "all IDs" array sorted based on filter titles
  //sortComparer: (a, b) => a.title.localeCompare(b.title),
});

const jobsFilters = createSlice({
  name: "jobsFilters",
  initialState: jobsFilterAdapter.getInitialState(),
  reducers: {
    // Can pass adapter functions directly as case reducers.  Because we're passing this
    // as a value, `createSlice` will auto-generate the `filterAdded` action type / creator
    setAllJobsFilters: jobsFilterAdapter.setAll,
    addJobsFilter: jobsFilterAdapter.addOne,
    removeJobsFilter: jobsFilterAdapter.removeOne,
    removeAllJobsFilters: jobsFilterAdapter.removeAll,
    updateJobsFilter: jobsFilterAdapter.updateOne,
    updateManyJobsFilters: jobsFilterAdapter.updateMany,
  },
});

export const {
  setAllJobsFilters,
  addJobsFilter,
  removeJobsFilter,
  removeAllJobsFilters,
  updateJobsFilter,
  updateManyJobsFilters,
} = jobsFilters.actions;

export default jobsFilters.reducer;
