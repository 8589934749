import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface productSearchState {
  query?: string;
}

const initialState: productSearchState = {};

export const productSearchSlice = createSlice({
  name: "productSearch",
  initialState,
  reducers: {
    setProductSearchQuery: (state, action: PayloadAction<string>) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.query = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setProductSearchQuery } = productSearchSlice.actions;

export default productSearchSlice.reducer;
