import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type Item = any;

interface MobileMenuState {
  isOpen: boolean;
  activeItem?: Item;
}

const initialState: MobileMenuState = {
  isOpen: false,
};

export const mobileMenuSlice = createSlice({
  name: "mobileMenu",
  initialState,
  reducers: {
    view: (state, action: PayloadAction<Item>) => {
      state.isOpen = true;
      state.activeItem = action.payload;
    },
    close: (state) => {
      state.isOpen = false;
      state.activeItem = null;
    },
    open: (state) => {
      state.isOpen = true;
      state.activeItem = null;
    },
  },
});

export const { view, close, open } = mobileMenuSlice.actions;

export default mobileMenuSlice.reducer;
