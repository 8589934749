export const SITE_URL =
  process.env.NEXT_PUBLIC_SITE_URL || "http://localhost:3000";

export enum SESSION_STORAGE_KEYS {
  LAST_PLP_STATE = "LAST_PLP_STATE",
}

export const EJ_SERVICE_ID = process.env.NEXT_PUBLIC_EJ_SERVICE_ID as string;
export const EJ_PUBLIC_KEY = process.env.NEXT_PUBLIC_EJ_PUBLIC_KEY as string;
export const EJ_PRIVATE_KEY = process.env.NEXT_PUBLIC_EJ_PRIVATE_KEY as string;
export const EJ_TEMPLATE_VERIFICATION = process.env.NEXT_PUBLIC_EJ_TEMPLATE_VERIFICATION as string;

export const MS_DYNAMICS_365_CLIENT_ID = process.env
  .MS_DYNAMICS_365_CLIENT_ID as string;

export const MS_DYNAMICS_365_CLIENT_SECRET = process.env
  .MS_DYNAMICS_365_CLIENT_SECRET as string;

export const MS_DYNAMICS_365_BASE_URL = process.env
  .MS_DYNAMICS_365_BASE_URL as string;

export const MS_DYNAMICS_365_TENANT_ID = process.env
  .MS_DYNAMICS_365_TENANT_ID as string;
