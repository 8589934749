import { SVGProps } from "react";

type Props = SVGProps<SVGSVGElement>;

/**
 * Optimized with https://jakearchibald.github.io/svgomg/
 */
const SVGEyeNoOpen: React.FC<Props> = (props) => {

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <rect width="24" height="24" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 12C10 13.102 10.898 14 12 14C13.102 14 14 13.102 14 12C14 10.898 13.102 10 12 10C10.898 10 10 10.898 10 12Z"
        stroke="#C11113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 12C5.258 15.512 8.364 18 12 18C15.636 18 18.742 15.512 20 12C18.742 8.488 15.636 6 12 6C8.364 6 5.258 8.488 4 12Z"
        stroke="#C11113"
      />
      <path d="M4.75 5.70312L17.645 18.6041" stroke="#C11113" />
      <path d="M5.43555 5L18.3305 17.902" stroke="white" />
    </svg>
  );
};

export default SVGEyeNoOpen;
