import { SVGProps } from "react";

type Props = SVGProps<SVGSVGElement>;

/**
 * Optimized with https://jakearchibald.github.io/svgomg/
 */
const SVGCarouselSwipe: React.FC<Props> = (props) => (
  <svg
    /*  width="58"
    height="34" */
    viewBox="0 0 58 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.707 1.707A1 1 0 0 0 16.293.293l1.414 1.414ZM1 17l-.707-.707a1 1 0 0 0 0 1.414L1 17Zm15.293 16.707a1 1 0 0 0 1.414-1.414l-1.414 1.414Zm0-33.414-16 16 1.414 1.414 16-16L16.293.293Zm-16 17.414 16 16 1.414-1.414-16-16-1.414 1.414ZM40.293 32.293a1 1 0 0 0 1.414 1.414l-1.414-1.414ZM57 17l.707.707a1 1 0 0 0 0-1.414L57 17ZM41.707.293a1 1 0 1 0-1.414 1.414L41.707.293Zm0 33.414 16-16-1.414-1.414-16 16 1.414 1.414Zm16-17.414-16-16-1.414 1.414 16 16 1.414-1.414Z"
      fill="#fff"
    />
  </svg>
);

export default SVGCarouselSwipe;
