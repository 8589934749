import { UserData } from "@/lib/context/auth";

const defaultOptions = {
  method: "POST",
  headers: {
    "Content-Type": "application/json",
    "Accept": "application/json",
  },
  body: JSON.stringify({}),
};

export async function createUserWithEmailAndPassword(
  email: string,
  password: string,
  data: UserData
) {

  const options = defaultOptions
  options.body = JSON.stringify({
    email,
    password,
    data,
  });

  const response = await fetch(`${process.env.NEXT_PUBLIC_MIDDLEWARE_URL}/register`, options);

  if (response.status !== 200) {
    const error = await response.json()

    if (error.code.indexOf('already') !== -1) {
      throw new Error('Email already in use')
    }
    throw new Error(error.code)
  }

  return true
}

export async function applyActionCode(
  oobCode: string,
) {

  const options = defaultOptions
  options.body = JSON.stringify({
    oobCode,
  });

  const response = await fetch(`${process.env.NEXT_PUBLIC_MIDDLEWARE_URL}/apply_action`, options);

  if (response.status !== 200) {
    const error = await response.json()
    throw new Error(error.code)
  }

  return true
}

export async function checkActionCode(
  oobCode: string,
) {

  const options = defaultOptions
  options.body = JSON.stringify({
    oobCode,
  });

  const response = await fetch(`${process.env.NEXT_PUBLIC_MIDDLEWARE_URL}/check_action`, options);
  const result = await response.json()

  if (response.status !== 200) {
    throw new Error(result)
  }

  return result
}

export async function confirmPasswordReset(
  oobCode: string,
  password: string,
) {

  const options = defaultOptions
  options.body = JSON.stringify({
    oobCode,
    password,
  });

  const response = await fetch(`${process.env.NEXT_PUBLIC_MIDDLEWARE_URL}/reset`, options);

  if (response.status !== 200) {
    const error = await response.json()
    throw new Error(error)
  }

  return true
}

export async function signInWithEmailAndPassword(
  email: string,
  password: string,
) {

  const options = defaultOptions
  options.body = JSON.stringify({
    email,
    password,
  });

  const response = await fetch(`${process.env.NEXT_PUBLIC_MIDDLEWARE_URL}/login`, options);
  const result = await response.json()

  if (response.status !== 200) {
    throw new Error(result)
  }

  return result
}

export async function sendPasswordResetEmail(
  email: string,
) {

  const options = defaultOptions
  options.body = JSON.stringify({
    email,
  });

  const response = await fetch(`${process.env.NEXT_PUBLIC_MIDDLEWARE_URL}/send_reset`, options);

  if (response.status !== 200) {
    const error = await response.json()
    throw new Error(error)
  }

  return true
}

export async function getUser(
  userId: string,
) {

  const options = defaultOptions
  options.body = JSON.stringify({
    userId,
  });

  const response = await fetch(`${process.env.NEXT_PUBLIC_MIDDLEWARE_URL}/get_user`, options);
  const result = await response.json()

  if (response.status !== 200) {
    throw new Error(result)
  }

  return result
}

export async function updateUser(
  userId: string,
  data: UserData
) {

  const options = defaultOptions
  options.body = JSON.stringify({
    userId,
    data,
  });

  const response = await fetch(`${process.env.NEXT_PUBLIC_MIDDLEWARE_URL}/update_user`, options);
  const result = await response.json()

  if (response.status !== 200) {
    throw new Error(result)
  }

  return result
}
