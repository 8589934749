import { resetButton } from "@/lib/styles";
import { smallLabelTypographicStyle } from "@/lib/styles/helpers";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  height: 24px;
  align-items: center;
  padding: 0 1rem;
  ${({ theme }) => theme.mediaQueries.laptop} {
    padding: 0;
  }
`;

export const LogoutButton = styled.button`
  ${resetButton}
  ${smallLabelTypographicStyle}
  cursor: pointer;
  color: ${({ theme }) => theme.colors.darkMiddle};
  margin-left: 16px;
  height: 24px;
  display: grid;
  place-items: center;
`;

export const UserIconWrapper = styled.div`
  display: grid;
  place-items: center;
  box-sizing: border-box;
  width: 24px;
  height: 24px;
  margin-right: 8px;
  svg {
    width: 12px;
    height: 12px;
  }
`;
export const UserWapper = styled.div`
  display: flex;
  cursor: pointer;
`;

export const Username = styled.span`
  ${smallLabelTypographicStyle}
  color: ${({ theme }) => theme.colors.red};
  display: none;
  ${({ theme }) => theme.mediaQueries.tablet} {
    display: block;
  }
`;
