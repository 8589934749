import { MainLayoutQuery } from "@/lib/sanity/queries";
import { useAppSelector } from "@/lib/redux/hooks";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";
import { Container } from "./styles";

const ExternalLink = dynamic(() => import("./ExternalLink"));
const InternalLink = dynamic(() => import("./InternalLink"));
const Menu = dynamic(() => import("./Menu"));

type Props = {
  items: MainLayoutQuery["header"]["navigation"]["items"];
};

const DesktopMenu: React.FC<Props> = (props) => {
  const { items } = props;
  const containerRef = useRef<HTMLDivElement>(null);
  const activeTab = useAppSelector((state) => state.desktopMenu.activeTab);

  // Keep content around to prevent container to
  // change height during the animation
  const [currentItem, setCurrentItem] = useState<any>();
  // Keep locale in the dependencies so that content gets translated
  const { locale } = useRouter();

  useEffect(() => {
    const currentItem = items?.find((item) => item?._key === activeTab?._key);
    if (currentItem) setCurrentItem(currentItem);
  }, [locale, items, activeTab]);

  return (
    <Container ref={containerRef}>
      {currentItem?.content?.map((item: any) => {
        if (typeof item._key !== "string") return null;
        switch (item._type) {
          case "menu":
            return <Menu key={item._key} {...item} />;
          case "internalLink":
            return <InternalLink key={item._key} {...item} />;
          case "externalLink":
            return <ExternalLink key={item._key} {...item} />;
          default:
            return null;
        }
      })}
    </Container>
  );
};

export default DesktopMenu;
