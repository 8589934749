import { SVGProps } from "react";

type Props = SVGProps<SVGSVGElement>;

/**
 * Optimized with https://jakearchibald.github.io/svgomg/
 */
const SVGEyeOpen: React.FC<Props> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <rect width="24" height="24" />
      <path
        d="M10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10C10.9 10 10 10.9 10 12Z"
        stroke="#C11113"
      />
      <path
        d="M4 12C5.3 15.5 8.4 18 12 18C15.6 18 18.7 15.5 20 12C18.7 8.5 15.6 6 12 6C8.4 6 5.3 8.5 4 12Z"
        stroke="#C11113"
      />
    </svg>
  );
};

export default SVGEyeOpen;
