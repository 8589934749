import { AuthContext, User } from "@/lib/context/auth";
import { PropsWithChildren, useEffect, useState } from "react";
import { fetcher } from "@/lib/helpers";

type Props = PropsWithChildren<{}>;

const AuthProvider: React.FC<Props> = ({ children }) => {
  const [user, setUser] = useState<User | null>();

  const clearUser = () => {
    setUser(null)
  }

  const updateUser = (user: User) => {
    setUser(user)
  }

  useEffect(() => {
    fetcher('/api/firebase/user', {})
    .then(user => setUser(user.user))
  }, [])

  return (
    <AuthContext.Provider
      value={{ user, clearUser, updateUser }}
    >
      {
        /* Important: always render children, otherwise SSR won't work. */
        children
      }
    </AuthContext.Provider>
  );
};

export default AuthProvider;
