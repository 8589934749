import {
  createEntityAdapter,
  createSlice,
  EntityId,
  EntityState,
  PayloadAction,
  Update,
} from "@reduxjs/toolkit";

export type ProductFilterOption = {
  value: string;
  selected: boolean;
  count?: number;
};

export const productFilterOptionAdapter =
  createEntityAdapter<ProductFilterOption>({
    // Assume IDs are stored in a field other than `filter.id`
    selectId: (filter) => filter.value,
    // Keep the "all IDs" array sorted based on filter titles
    //sortComparer: (a, b) => a.title.localeCompare(b.value),
  });

export type ProductFilter = {
  id: string;
  title: string;
  main: boolean | null;
  options: EntityState<ProductFilterOption>;
};

export const productFilterAdapter = createEntityAdapter<ProductFilter>({
  // Assume IDs are stored in a field other than `filter.id`
  selectId: (filter) => filter.id,
  // Keep the "all IDs" array sorted based on filter titles
  //sortComparer: (a, b) => a.title.localeCompare(b.title),
});

const productFiltersSlice = createSlice({
  name: "productFilters",
  initialState: productFilterAdapter.getInitialState(),
  reducers: {
    // Can pass adapter functions directly as case reducers.  Because we're passing this
    // as a value, `createSlice` will auto-generate the `filterAdded` action type / creator
    setAllProductFilters: productFilterAdapter.setAll,
    updateProductFilterOption: (
      state,
      action: PayloadAction<{
        filterId: EntityId;
        optionId: EntityId;
        changes: Partial<ProductFilterOption>;
      }>
    ) => {
      const { filterId, optionId, changes } = action.payload;
      const filter = productFilterAdapter
        .getSelectors()
        .selectById(state, filterId);
      if (!filter) throw new Error("Filter not found");

      state = productFilterAdapter.updateOne(state, {
        id: filter.id,
        changes: {
          options: productFilterOptionAdapter.updateOne(filter.options, {
            id: optionId,
            changes,
          }),
        },
      });
    },
    updateManyProductFilterOptions: (
      state,
      action: PayloadAction<{
        filterId: EntityId;
        updates: readonly Update<ProductFilterOption>[];
      }>
    ) => {
      const { filterId, updates } = action.payload;
      const filter = productFilterAdapter
        .getSelectors()
        .selectById(state, filterId);
      if (!filter) throw new Error("Filter not found");
      state = productFilterAdapter.updateOne(state, {
        id: filterId,
        changes: {
          options: productFilterOptionAdapter.updateMany(
            filter.options,
            updates
          ),
        },
      });
    },
  },
});

export const {
  setAllProductFilters,
  updateProductFilterOption,
  updateManyProductFilterOptions,
} = productFiltersSlice.actions;

export default productFiltersSlice.reducer;
