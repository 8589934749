import { SVGProps } from "react";

type Props = SVGProps<SVGSVGElement>;

/**
 * Optimized with https://jakearchibald.github.io/svgomg/
 */
const SVGInfo: React.FC<Props> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      fillRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
      clipRule="evenodd"
      viewBox="0 0 22 22"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.75 15.5V8.75H8.75V10.25H10.25V15.5H8V17H14V15.5H11.75ZM11 4.25C10.4 4.25 9.8 4.7 9.8 5.45C9.8 6.2 10.4 6.5 11 6.5C11.6 6.5 12.2 6.05 12.2 5.3C12.2 4.55 11.6 4.25 11 4.25ZM11 21.5C5.15 21.5 0.5 16.85 0.5 11C0.5 5.15 5.15 0.5 11 0.5C16.85 0.5 21.5 5.15 21.5 11C21.5 16.85 16.85 21.5 11 21.5ZM11 2C6.05 2 2 6.05 2 11C2 15.95 6.05 20 11 20C15.95 20 20 15.95 20 11C20 6.05 15.95 2 11 2Z"
        fill="#ffffff"
      />
    </svg>
  );
};

export default SVGInfo;
