import { MainLayoutQuery } from "@/lib/sanity/queries";
import { useAppSelector } from "@/lib/redux/hooks";
import { useRef } from "react";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import ActiveMenu from "./ActiveMenu";
import StartMenu from "./StartMenu";
import { Container } from "./styles";

type Props = {
  items: MainLayoutQuery["header"]["navigation"]["items"];
};

const Content: React.FC<Props> = (props) => {
  const { items } = props;
  const activeItem = useAppSelector((state) => state.mobileMenu.activeItem);
  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <SwitchTransition>
      <CSSTransition
        key={activeItem ? "active" : "start"}
        nodeRef={containerRef}
        classNames="animation"
        addEndListener={(done) => {
          containerRef.current?.addEventListener("transitionend", done, false);
        }}
      >
        <Container ref={containerRef}>
          {activeItem ? (
            <ActiveMenu items={items} />
          ) : (
            <StartMenu items={items} />
          )}
        </Container>
      </CSSTransition>
    </SwitchTransition>
  );
};

export default Content;
