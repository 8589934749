import styled from "styled-components";
import { resetButton } from "@/lib/styles";

export const Button = styled.button`
  ${resetButton}

  color: ${({ theme }) => theme.colors.black};
  height: auto;
  padding: 1rem;
  box-sizing: content-box;

  display: flex;
  align-items: center;
  cursor: pointer;

  &.animation-enter {
    opacity: 0;
  }
  &.animation-enter-active {
    opacity: 1;
    transition: opacity 100ms;
  }
  &.animation-exit {
    opacity: 1;
  }
  &.animation-exit-active {
    opacity: 0;
    transition: opacity 100ms;
  }

  ${({ theme }) => theme.mediaQueries.tablet} {
    padding: 0 1.625rem;
  }
  ${({ theme }) => theme.mediaQueries.laptop} {
    display: none;
  }
`;
