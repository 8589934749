/* https://www.figma.com/file/16YSFRr06xdFYJFqNgvv41/Bormioli-Product-Design?node-id=283%3A43041 */

import { SVGCaretDown } from "@/lib/svg";
import { useRouter } from "next/router";
import { useState } from "react";
import {
  LanguagePanelContainer,
  IconWrapper,
  CurrentLanguage,
  LanguageSelectButton,
  SelectorContainer,
  SelectorInner,
  LocaleButton,
} from "./styles";

type LanguagePanelProps = {
  $desktopMenuIsOpen?: boolean;
};

export const LanguagePanel: React.FC<LanguagePanelProps> = ({
  $desktopMenuIsOpen,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const router = useRouter();
  const { locale, locales, pathname, asPath, query } = useRouter();

  function toggleSelector() {
    if (isOpen) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  }
  function changeLocale(newLocale: string) {
    if (newLocale == locale) {
      return;
    } else {
      router.push({ pathname, query }, asPath, {
        locale: newLocale,
      });
      toggleSelector(); // TODO wait for the transition to end?
    }
  }
  return (
    <LanguagePanelContainer>
      <LanguageSelectButton onClick={toggleSelector}>
        <CurrentLanguage $desktopMenuIsOpen={$desktopMenuIsOpen}>
          {locale}
        </CurrentLanguage>
        <IconWrapper $isOpen={isOpen} $desktopMenuIsOpen={$desktopMenuIsOpen}>
          <SVGCaretDown />
        </IconWrapper>
      </LanguageSelectButton>
      <SelectorContainer $isOpen={isOpen}>
        <SelectorInner>
          {locales &&
            locales.map((localeName, index) => (
              <LocaleButton
                onClick={() => changeLocale(localeName)}
                key={`${localeName}-${index}`}
                $isActive={localeName == locale}
              >
                {localeName}
              </LocaleButton>
            ))}
        </SelectorInner>
      </SelectorContainer>
    </LanguagePanelContainer>
  );
};

export default LanguagePanelContainer;
