import styled from "styled-components";
import { SVGArrowRight } from "@/lib/svg";
import CallToAction from "@/components/Button";
import { forwardRef } from "react";
import { CallToActionProps } from "@/components/CallToAction";

export const FormContainer = styled.div`
  margin: 0 3rem;

  ${({ theme }) => theme.mediaQueries.tablet} {
    max-width: 30rem;
    margin: 0 auto;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    max-width: 39.375rem;
  }

  ${({ theme }) => theme.mediaQueries.desktop} {
    max-width: 42.5rem;
  }
`;

export const FormWrapper = styled.div`
  font-family: ${({ theme }) => theme.fontFamilies.secondary};
  display: flex;
  flex-flow: column;
  flex: 0.5;
  &:not(:last-of-type) {
    padding-right: 1.5rem;
  }
`;

interface InputWrapperProps {
  isFull?: boolean;
}

export const InputWrapper = styled.div<InputWrapperProps>`
  box-sizing: border-box;
  margin-bottom: 3.125rem;
  max-width: 100%;
  position: relative;
  width: 100%;

  ${({ theme }) => theme.mediaQueries.laptop} {
    max-width: ${(props) => (props.isFull ? "100%" : "calc(50% - 1.5rem)")};
  }

  label {
    display: none;
  }

  input {
    width: 100%;
  }

  .inputIcon.error input,
  &.error input {
    border-bottom: 1px solid ${({ theme }) => theme.colors.red};
    color: ${({ theme }) => theme.colors.red};

    ::placeholder,
    ::-webkit-input-placeholder {
      color: ${({ theme }) => theme.colors.red};
    }
    :-ms-input-placeholder {
      color: ${({ theme }) => theme.colors.red};
    }
  }

  &.error .select-control {
    border-bottom: 1px solid ${({ theme }) => theme.colors.red};
  }

  &.error .select-placeholder {
    color: ${({ theme }) => theme.colors.red};
  }

  .wish-list-product & {
    padding-left: 0;
    padding-right: 0;
    max-width: 100%;
    margin-bottom: 0.75rem;
    ${({ theme }) => theme.mediaQueries.desktop} {
      margin-bottom: 1.75rem;
    }
  }
`;

export const InputFileWrapper = styled.div<InputWrapperProps>`
  box-sizing: border-box;
  margin-bottom: 3.125rem;
  max-width: ${(props) => (props.isFull ? "100%" : "50%")};
  position: relative;
  width: 100%;

  input {
    display: none;
  }

  label {
    border-bottom: 1px solid ${({ theme }) => theme.colors.middleGrey};
    font-size: 1.25rem;
    display: flex;
    font-family: ${({ theme }) => theme.fontFamilies.secondary};
    justify-content: space-between;
    padding: 1px 1px 0.5rem 1px;
    width: 100%;
  }

  &.error label {
    border-bottom: 1px solid ${({ theme }) => theme.colors.red};
    color: ${({ theme }) => theme.colors.red};
  }
`;

export const FilePlaceholder = styled.div<InputWrapperProps>`
  color: ${({ theme }) => theme.colors.middleGrey};
  font-family: ${({ theme }) => theme.fontFamilies.secondary};

  .error & {
    color: ${({ theme }) => theme.colors.red};
  }
`;

export const InputTextArea = styled.div`
  box-sizing: border-box;
  margin-bottom: 3.125rem;
  position: relative;
  width: 100%;

  label {
    display: none;
  }

  textarea {
    border: none;
    border-bottom: 1px solid ${({ theme }) => theme.colors.middleGrey};
    font-size: 1.25rem;
    padding-bottom: 0.5rem;
    outline: none;
    resize: none;
    width: 100%;
    font-family: ${({ theme }) => theme.fontFamilies.secondary};

    ::placeholder,
    ::-webkit-input-placeholder {
      color: ${({ theme }) => theme.colors.middleGrey};
    }
    :-ms-input-placeholder {
      color: ${({ theme }) => theme.colors.middleGrey};
    }
  }

  &.error textarea {
    border-bottom: 1px solid ${({ theme }) => theme.colors.red};
    color: ${({ theme }) => theme.colors.red};

    ::placeholder,
    ::-webkit-input-placeholder {
      color: ${({ theme }) => theme.colors.red};
    }
    :-ms-input-placeholder {
      color: ${({ theme }) => theme.colors.red};
    }
  }
`;

export const InputIcon = styled.div`
  position: relative;
  margin-bottom: 0; // avoid enlarging field area when error message is visible

  &.error input {
    border-bottom: 1px solid ${({ theme }) => theme.colors.red};
    color: ${({ theme }) => theme.colors.red};
  }

  input {
    width: 100%;
    padding-right: 0;
  }
  svg {
    position: absolute;
    top: 2px;
    right: 0;
    cursor: pointer;
  }
`;

export const InputCheckbox = styled.div`
  margin-bottom: 2.5rem;

  .error span:last-child {
    border: 1px solid ${({ theme }) => theme.colors.red};
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    padding-right: 1.5rem;
  }

  a {
    color: ${({ theme }) => theme.colors.red};
  }
`;

export const SubmitButtonWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;

  ${({ theme }) => theme.mediaQueries.laptop} {
    padding-right: 1.5rem;
  }
`;

export const Input = styled.input`
  max-width: 100%;
  padding-bottom: 0.5rem;
  background: transparent;
  outline: 0;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.middleGrey};
  font-size: 1.25rem;
  border-radius: 0;
  font-family: ${({ theme }) => theme.fontFamilies.secondary};

  &:not(:last-of-type) {
    margin-bottom: 3.125rem;
  }

  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${({ theme }) => theme.colors.middleGrey};
  }
  :-ms-input-placeholder {
    color: ${({ theme }) => theme.colors.middleGrey};
  }
`;

export const ErrorMessage = styled.span`
  color: ${({ theme }) => theme.colors.red};
  font-size: 0.875rem;
  padding-top: 0.5rem;
  display: none;
  position: absolute;
  background-color: ${({theme})=>theme.colors.white};

  .error &,
  .error ~ & {
    display: block;
    ${({ theme }) => theme.mediaQueries.laptop} {
      // Temporary dirty fix to avoid situation in which the error newlines are overlapping the element below.
      width: 800px;
    }
  }
`;

export const FormButton = forwardRef<HTMLButtonElement, any>(
  function FormButton(props, ref) {
    return (
      <CallToAction
        ref={ref}
        type="submit"
        as="button"
        variant="primaryBoxed"
        {...props}
        style={{ alignItems: "center", alignSelf: "start" }}
        icon={<SVGArrowRight />}
      >
        {props.children}
      </CallToAction>
    );
  }
);

export const FormButtonUnboxed = forwardRef<HTMLButtonElement, any>(
  function FormButtonUnboxed(props, ref) {
    return (
      <CallToAction
        ref={ref}
        as="a"
        variant="primaryUnboxed"
        {...props}
        style={{ paddingLeft: 0, width: "unset" }}
        icon={<SVGArrowRight />}
      >
        {props.children}
      </CallToAction>
    );
  }
);
