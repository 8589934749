import { useAppSelector } from "@/lib/redux/hooks";
import { useMemo } from "react";
import { Container } from "./styles";
import ExternalLink from "./ExternalLink";
import InternalLink from "./InternalLink";
import Menu from "./Menu";
import { MainLayoutQuery } from "@/lib/sanity/queries";

type Props = {
  items?: MainLayoutQuery["header"]["navigation"]["items"];
};

const ActiveMenu: React.FC<Props> = (props) => {
  const { items } = props;

  const activeItem = useAppSelector((state) => state.mobileMenu.activeItem);

  const currentItem = useMemo(
    () => items?.find((item) => item?._key === activeItem?._key),
    [items, activeItem]
  );

  return (
    <Container>
      {currentItem?._type === "headerTab" &&
        currentItem?.content?.map((item) => {
          if (typeof item._key !== "string") return null;
          switch (item._type) {
            case "menu":
              return <Menu key={item._key} {...item} />;
            case "internalLink":
              return <InternalLink key={item._key} {...item} />;
            case "externalLink":
              return <ExternalLink key={item._key} {...item} />;
            default:
              return null;
          }
        })}
    </Container>
  );
};

export default ActiveMenu;
