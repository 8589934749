import { SVGHamburger } from "@/lib/svg";
import { useAppDispatch } from "@/lib/redux/hooks";
import { open } from "@/lib/redux/mobileMenu/mobileMenuSlice";
import { Button } from "./styles";

type Props = {};

const MenuButton: React.FC<Props> = () => {
  const dispatch = useAppDispatch();

  return (
    <Button onClick={() => dispatch(open())}>
      <SVGHamburger title="Open menu" style={{ width: "1.25rem" }} />
    </Button>
  );
};

export default MenuButton;
