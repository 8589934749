type L_KEYS = "it" | "en" | "de" | "fr" | "es";

export type Translation = Partial<Record<L_KEYS, string>>;

enum T_ENUM {
  "marketingPage.location.address",
  "marketingPage.location.email",
  "marketingPage.location.phone",
  "page.goBack",
  "newsPage.filters.year",
  "newsPage.searchbar",
  "newsPage.countLabel",
  "newsPage.loadMore",
  "newsPage.articles",
  "articlePage.nextArticle",
  "articlePage.prevArticle",
  "articlePage.date",
  "articlePage.website",
  "contestStatus.deadline",
  "contestStatus.prize",
  "contestStatus.open",
  "contestStatus.closed",
  "productListing.allProducts",
  "productListing.filtersButton",
  "productListing.searchButton",
  "productListing.searchPlaceholder",
  "productListing.productsCounter",
  "productListing.productsCounterOne",
  "productListing.loadMore",
  "productListing.downloadPdf",
  "ProductDetail.downloadPdf",
  "ProductDetail.addWishlist",
  "ProductDetail.removeWishlist",
  "ProductDetail.relatedTitle",
  "ProductDetail.relatedSubTitle",
  "ContactPage.locationsTitle",
  "ContactPage.locationsSubTitle",
  "loginPage.title",
  "loginPage.description",
  "loginPage.buttonTextLogin",
  "loginPage.labelRegister",
  "loginPage.buttonTextRegister",
  "loginPage.buttonTextForgot",
  "loginPage.errorLogin",
  "registerPage.title",
  "registerPage.description",
  "registerPage.privacy",
  "registerPage.buttonTextRegister",
  "registerPage.required",
  "forgotPasswordPage.title",
  "forgotPasswordPage.description",
  "forgotPasswordPage.buttonTextForgot",
  "forgotPasswordPage.buttonTextLogin",
  "forgotPasswordPage.errorForgot",
  "forgotPasswordPage.successTitle",
  "forgotPasswordPage.successDescription",
  "forgotPasswordPage.successButtonTextLogin",
  "migratedUserPasswordResetPage.title",
  "migratedUserPasswordResetPage.description",
  "migratedUserPasswordResetPage.buttonTextForgot",
  "migratedUserPasswordResetPage.buttonTextLogin",
  "migratedUserPasswordResetPage.errorForgot",
  "migratedUserPasswordResetPage.successTitle",
  "migratedUserPasswordResetPage.successDescription",
  "migratedUserPasswordResetPage.successButtonTextLogin",
  "toBeApprovedPage.title",
  "toBeApprovedPage.description",
  "toBeApprovedPage.backButton",
  "emailVerifiedPage.title",
  "emailVerifiedPage.description",
  "emailVerifiedPage.backButton",
  "profilePage.buttonTextSave",
  "profilePage.logout",
  "profilePage.successTitle",
  "profilePage.successDescription",
  "profilePage.successButtonTextIndex",
  "jobsPage.filters.country",
  "wishlist.emptyTitle",
  "wishlist.emptySubTitle",
  "wishlist.emptyButtonText",
  "wishlist.successTitle",
  "wishlist.successSubTitle",
  "wishlist.successButtonText",
  "wishlist.selectedProducts",
  "wishlist.removeProduct",
  "wishlist.selectQuantity",
  "wishlist.quantity1",
  "wishlist.quantity2",
  "wishlist.quantity3",
  "wishlist.quantity4",
  "wishlist.quantity5",
  "wishlist.quantity6",
  "wishlist.quantity7",
  "contactPage.successTitle",
  "contactPage.successSubTitle",
  "contactPage.successButtonText",
  "contactPage.loadingButtonSubmit",
  "investor.financeCalendar",
  "investor.documents",
  "investor.filters.category",
  "investor.filters.quarter",
  "investor.filters.year",
  "investor.time",
  "filters.removeAll",
  "resetPasswordPage.successTitle",
  "resetPasswordPage.successButton",
  "resetPasswordPage.title",
  "resetPasswordPage.button",
  "resetPasswordPage.inputPassword",
  "resetPasswordPage.inputPasswordConf",
  "signupPage.successTitle",
  "signupPage.successDescription",
  "signupPage.successButtonTextLogin",
  "errorPage.Title",
  "errorPage.Description",
  "errorPage.ButtonTextLogin",
  "position.apply",
  "infoTooltip.Title",
  "infoTooltip.Description"
}

type T_KEYS = `${keyof typeof T_ENUM}`;

export const t: Record<T_KEYS, Translation> = {
  "marketingPage.location.address": {
    it: "Indirizzo",
    en: "Address",
    de: "Adresse",   
    fr: "Adresse",
    es: "Dirección",
  },
  "marketingPage.location.email": {
    it: "E-mail",
    en: "E-mail",
    de: "E-mail",
    fr: "E-mail",
    es: "E-mail",
  },
  "marketingPage.location.phone": {
    it: "Telefono",
    en: "Phone",
    de: "Telefon",
    fr: "Téléphone",
    es: "Telefono",
  },
  "newsPage.countLabel": {
    it: "notizie",
    en: "news",
    de: "Nachricht",
    fr: "infos",
    es: "noticias",
  },
  "newsPage.filters.year": {
    it: "Anno",
    en: "Year",
    de: "Jahr",
    fr: "Année",
    es: "Año",
  },
  "newsPage.loadMore": {
    it: "Carica altre notizie",
    en: "Load more news",
    de: "Mehr Nachrichten hochladen",
    fr: "Charger plus infos",
    es: "Cargar más noticias",
  },
  "newsPage.articles": {
    it: "Articoli",
    en: "Articles",
    de: "Artikel",
    fr: "Articles",
    es: "Articulos",
  },
  "newsPage.searchbar": {
    it: "Cerca",
    en: "Search",
    de: "Suche",
    fr: "Chercher",
    es: "Búsqueda",
  },
  "page.goBack": {
    it: "Indietro",
    en: "Go back",
    de: "Geh zurück",
    fr: "En arrière",
    es: "Regresa",
  },
  "contestStatus.deadline": {
    it: "Scadenza",
    en: "Due date",
    de: "Termin",
    fr: "Expiration",
    es: "Expiracion",
  },
  "contestStatus.prize": {
    it: "Premio",
    en: "Award",
    de: "Preis",
    fr: "Prix",
    es: "Recompensa",
  },
  "contestStatus.open": {
    it: "Aperta",
    en: "Open",
    de: "Offen",
    fr: "En cours",
    es: "En curso",
  },
  "contestStatus.closed": {
    it: "Chiusa",
    en: "Closed",
    de: "Geschlossen",
    fr: "Expirè",
    es: "Terminada",
  },
  "articlePage.nextArticle": {
    it: "Notizia successiva",
    en: "Next news",
    de: "Nächste Neuigkeiten",
    fr: "Info suivant",
    es: "Próximas noticias",
  },
  "articlePage.prevArticle": {
    it: "Notizia precedente",
    en: "Previous news",
    de: "frühere Nachrichten",
    fr: "Info précédent",
    es: "Noticias anteriores",
  },
  "articlePage.date": {
    it: "Data",
    en: "Date",
    de: "Datum",
    fr: "Date",
    es: "Fecha",
  },
  "articlePage.website": {
    it: "Sito web",
    en: "Website",
    de: "Webseite",
    fr: "Site Internet",
    es: "Sitio web",
  },
  "productListing.allProducts": {
    it: "Tutti i prodotti",
    en: "All products",
    de: "Alle Produkte",
    fr: "Tous les produits",
    es: "Todos los productos",
  },
  "productListing.filtersButton": {
    it: "Tutti i filtri",
    en: "All filters",
    de: "Alle Filter",
    fr: "Tous les filtres",
    es: "Todos los filtros",
  },
  "productListing.loadMore": {
    it: "Carica altri prodotti",
    en: "Load more products",
    de: "Andere Produkte hochladen",
    fr: "Télécharger d'autres produits",
    es: "Cargar otros productos",
  },
  "productListing.downloadPdf": {
    it: "Scarica il catalogo",
    en: "Download the catalog",
    de: "Katalog herunterladen",
    fr: "Télécharger le catalogue",
    es: "Descargar el catálogo",
  },
  "productListing.searchButton": {
    it: "Codice prodotto",
    en: "Product code",
    de: "Produkt-Code",
    fr: "Code produit",
    es: "Código del producto",
  },
  "productListing.searchPlaceholder": {
    it: "Inserisci il codice",
    en: "Enter code",
    de: "Geben Sie den Code ein",
    fr: "Entrez le code",
    es: "Introduzca el código",
  },
  "productListing.productsCounterOne": {
    it: "prodotto",
    en: "product",
    de: "produkt",
    fr: "produit",
    es: "producto",
  },
  "productListing.productsCounter": {
    it: "prodotti",
    en: "products",
    de: "produkte",
    fr: "produits",
    es: "productos",
  },
  "ProductDetail.downloadPdf": {
    it: "Scarica scheda PDF",
    en: "Download PDF sheet",
    de: "PDF herunterladen",
    fr: "Télécharger fiche PDF",
    es: "Descargar ficha PDF",
  },
  "ProductDetail.addWishlist": {
    it: "Salva per preventivo",
    en: "Save for quote",
    de: "Für Angebot speichern",
    fr: "Sauvegarder pour devis",
    es: "Guardar para presupuesto",
  },
  "ProductDetail.removeWishlist": {
    it: "Rimuovi dal preventivo",
    en: "Remove from quote",
    de: "Aus Zitat entfernen",
    fr: "Supprimer de la liste",
    es: "Eliminar del presupuesto",
  },
  "ProductDetail.relatedTitle": {
    it: "Completa la soluzione",
    en: "Complete the solution",
    de: "Vervollständigen Sie die Lösung",
    fr: "Compléter la solution",
    es: "Completar la solución",
  },
  "ProductDetail.relatedSubTitle": {
    it: "Esplora i prodotti e le componenti compatibili per creare la soluzione più adatta alle tue esigenze.",
    en: "Explore compatible products and components to create the best solution for your needs.",
    de: "Entdecken Sie kompatible Produkte und Komponenten, um die beste Lösung für Ihre Bedürfnisse zu finden.",
    fr: "Explorez les produits et composants compatibles pour créer la meilleure solution pour vos besoins.",
    es: "Explore los productos y componentes compatibles para crear la mejor solución para sus necesidades.",
  },
  "ContactPage.locationsTitle": {
    it: "Radicati in Europa, presenti nel mondo",
    en: "Rooted in Europe, present in the world",
    de: "In Europa verwurzelt, in der Welt präsent",
    fr: "Enracinés en Europe, présents dans le monde",
    es: "Arraigada en Europa, presente en el mundo",
  },
  "ContactPage.locationsSubTitle": {
    it: "Con nove stabilimenti e cinque sedi e filiali commerciali siamo presenti in Italia, Francia, Germania e Stati Uniti. La nostra rete di vendita raggiunge più di 150 Paesi, e porta i nostri prodotti in tutto il mondo.",
    en: "With nine plants and five offices and sales subsidiaries, we are present in Italy, France, Germany, and the United States. Our sales network reaches more than 150 countries, and takes our products all over the world.",
    de: "Mit neun Werken und fünf Vertriebsbüros und Tochtergesellschaften sind wir in Italien, Frankreich, Deutschland und den Vereinigten Staaten vertreten. Unser Vertriebsnetz erstreckt sich über mehr als 150 Länder und bringt unsere Produkte in die ganze Welt.",
    fr: "Avec neuf usines et cinq bureaux de vente et société contrôlées, nous sommes présents en Italie, en France, en Allemagne et aux États-Unis. Notre réseau de vente s'étend à plus de 150 pays, et nos produits sont distribués dans le monde entier.",
    es: "Con nueve fábricas y cinco oficinas comerciales y filiales, estamos presentes en Italia, Francia, Alemania y Estados Unidos. Nuestra red de ventas llega a más de 150 países y lleva nuestros productos a todo el mundo.",
  },
  "loginPage.title": {
    it: "Sei già un utente registrato?",
    en: "Are you already a registered user?",
    de: "Sind Sie bereits ein registrierter Benutzer?",
    fr: "Vous êtes déjà un utilisateur enregistré?",
    es: "¿Ya es un usuario registrado?",
  },
  "loginPage.description": {
    it: "Inserisci la tua email e password per accedere all'area investitori del sito di Bormioli Pharma",
    en: "Enter your email and password to access the investor area of the Bormioli Pharma website.",
    de: "Geben Sie Ihre E-Mail-Adresse und Ihr Passwort ein, um Zugang zum Investorenbereich der Bormioli Pharma Website zu erhalten",
    fr: "Entrez votre adresse électronique et votre mot de passe pour accéder à la zone réservée aux investisseurs sur le site Web de Bormioli Pharma.",
    es: "Introduzca su correo electrónico y su contraseña para acceder a la zona de inversores del sitio web de Bormioli Pharma",
  },
  "loginPage.buttonTextLogin": {
    it: "Accedi",
    en: "Sign in",
    de: "Einloggen",
    fr: "Se connecter",
    es: "Acceder",
  },
  "loginPage.labelRegister": {
    it: "Non hai un account?",
    en: "No account?",
    de: "Sie haben noch kein Konto?",
    fr: "Vous n'avez pas de compte?",
    es: "¿No tiene una cuenta?",
  },
  "loginPage.buttonTextRegister": {
    it: "Registrati",
    en: "Register",
    de: "Registrieren Sie sich",
    fr: "S’inscrire",
    es: "Regístrese",
  },
  "loginPage.buttonTextForgot": {
    it: "Hai dimenticato la password?",
    en: "Forgot your password?",
    de: "Haben Sie Ihr Passwort vergessen?",
    fr: "Mot de passe oublié?",
    es: "¿Ha olvidado la contraseña?",
  },
  "loginPage.errorLogin": {
    it: "Email o password errate, riprova!",
    en: "Email or password incorrect, please try again!",
    de: "Email oder Passwort falsch, bitte versuchen Sie es erneut!",
    fr: "Courriel ou mot de passe erroné, veuillez réessayer!",
    es: "Correo electrónico o contraseña incorrectos, inténtalo de nuevo!",
  },
  "registerPage.title": {
    it: "Registrazione",
    en: "Registration",
    de: "Anmeldung",
    fr: "Inscription",
    es: "Registro",
  },
  "registerPage.description": {
    it: "Inserisci i tuoi dati per creare un nuovo account",
    en: "Enter your details to create a new account",
    de: "Geben Sie Ihre Daten ein, um ein neues Konto zu erstellen",
    fr: "Entrez vos coordonnées pour créer un nouveau compte",
    es: "Introduzca sus datos para crear una cuenta nueva",
  },
  "registerPage.privacy": {
    it: "Ho letto la normativa sulla <a href='/corporate/privacy-policy'>Privacy</a>*",
    en: "I have read the <a href='/corporate/privacy-policy'>Privacy Policy</a>*",
    de: "Ich habe die <a href='/corporate/privacy-policy'>Datenschutzbestimmungen gelesen</a>*",
    fr: "J'ai lu la <a href='/corporate/privacy-policy'>politique de confidentialité</a>",
    es: "He leído la <a href='/corporate/privacy-policy'>política de privacidad</a>",
  },
  "registerPage.buttonTextRegister": {
    it: "Crea un account",
    en: "Create an account",
    de: "Ein Konto erstellen",
    fr: "Créer un compte",
    es: "Crear una cuenta",
  },
  "registerPage.required": {
    it: "Obbligatorio",
    en: "Required",
    de: "Erforderlich",
    fr: "Obligatoire",
    es: "Obligatorio",
  },
  "forgotPasswordPage.title": {
    it: "Recupera la password",
    en: "Retrieve password",
    de: "Passwort wiederherstellen",
    fr: "Récupérer le mot de passe",
    es: "Recuperar la contraseña",
  },
  "forgotPasswordPage.description": {
    it: "Inserisci il tuo indirizzo email per recuperare la password",
    en: "Enter your email address to retrieve your password",
    de: "Geben Sie Ihre E-Mail-Adresse ein, um Ihr Passwort abzurufen",
    fr: "Entrez votre adresse e-mail pour récupérer l mot de passe",
    es: "Introduzca su dirección de correo electrónico para recuperar su contraseña",
  },
  "forgotPasswordPage.buttonTextForgot": {
    it: "Invia",
    en: "Send",
    de: "Senden",
    fr: "Envoyer",
    es: "Enviar",
  },
  "forgotPasswordPage.buttonTextLogin": {
    it: "O torna alla pagina di accesso",
    en: "Or go Back to the login page",
    de: "Oder gehen Sie zurück zur Anmeldeseite",
    fr: "Ou retournez à la page de connexion",
    es: "O volver a la página de inicio de sesión",
  },
  "forgotPasswordPage.errorForgot": {
    it: "Questa email non esiste, provane una valida!",
    en: "This e-mail does not exists, please try a valid one!",
    de: "Diese E-Mail existiert nicht, bitte versuchen Sie es mit einer gültigen!",
    fr: "Cet email n'existe pas, essayez un email valide!",
    es: "Este correo electrónico no existe, ¡intente con uno válido!",
  },
  "forgotPasswordPage.successTitle": {
    it: "Grazie!",
    en: "Thank you!",
    de: "Vielen Dank!",
    fr: "Merci!",
    es: "¡Gracias!",
  },
  "forgotPasswordPage.successDescription": {
    it: "A breve riceverai le istruzioni per il recupero della password",
    en: "You will receive password recovery instructions shortly.",
    de: "Sie erhalten in Kürze eine Anleitung zur Wiederherstellung des Passworts.",
    fr: "Vous recevrez sous peu des instructions pour la récupération de votre mot de passe.",
    es: "En breve recibirá las instrucciones para recuperar la contraseña.",
  },
  "forgotPasswordPage.successButtonTextLogin": {
    it: "Torna alla pagina di accesso",
    en: "Back to the login page",
    de: "Zurück zur Anmeldeseite",
    fr: "Retour à la page d’accès",
    es: "Volver a la página de inicio de sesión",
  },

  "migratedUserPasswordResetPage.title": {
    it: "Gentile investitore",
    en: "Dear Investor",
    de: "Sehr geehrte Anleger und Anlegerinnen",
    fr: "Cher investisseur",
    es: "Estimado inversor",
  },
  "migratedUserPasswordResetPage.description": {
    it: "Il nuovo sito web del Gruppo bormiolipharma.com, è on-line. L'abbiamo progettato per raccontare in modo più efficace i nostri prodotti, Il nostro know how, l'impegno per la salute delle persone e del pianeta.\n\n Con l'obiettivo di proteggere la sicurezza dei suoi dati, durante la migrazione verso una nuova infrastruttura più performante, la password del suo account non è stata conservata.\n\n Per accedere nuovamente all'area privata è necessario quindi creare una nuova password di accesso al suo account, inserendo la mail con cui abitualmente accedi all’area investori.",
    en: "The Group’s new website, bormiolipharma.com is now online. It has been designed to more effectively describe our products, know-how and our commitment to the health of the people and the planet.\n\nWith your data protection in mind, during the migration to the new, more performant infrastructure, your password was deleted. To access the private area, it is therefore necessary that you create a new password for your account.\n\nPlease insert the e-mail address with which you usually access the investor area. ",
    de: "Unsere neue Unternehmenswebsite bormiolipharma.com ist jetzt online. Wir haben sie so gestaltet, dass sie unsere Produkte, unser Know-how und unser Engagement für die Gesundheit der Menschen und den Planeten Erde anschaulicher darstellt.\n\nZum Schutz Ihrer Daten wurde bei der Migration auf die neue, leistungsfähigere Infrastruktur Ihr Passwort gelöscht. Um auf den privaten Bereich zugreifen zu können, ist es daher notwendig, dass Sie ein neues Passwort für Ihr Konto erstellen.\n\nBitte Sie die E-Mail-Adresse ein, mit der Sie üblicherweise auf den Anlegerbereich zugreifen.",
    fr: "Le nouveau site web du groupe bormiolipharma.com est en ligne. Nous l'avons conçu pour vous informer plus efficacement sur nos produits, notre savoir-faire et notre engagement pour la santé des personnes et de la planète.\n\n Pour protéger la sécurité de vos données, lors de la migration vers une nouvelle infrastructure plus performante, le mot de passe de votre compte n'a pas été conservé.\n\n Pour accéder à nouveau à l'espace privé, vous devez donc créer un nouveau mot de passe pour votre compte, en saisissant l'adresse e-mail avec laquelle vous accédez habituellement à l'espace investisseur.",
    es: "El nuevo sitio web del Grupo bormiolipharma.com ya está en línea. La hemos diseñado para informarle más eficazmente sobre nuestros productos, nuestros conocimientos técnicos y nuestro compromiso con la salud de las personas y del planeta.\n\n\Para proteger la seguridad de sus datos, durante la migración a una nueva infraestructura de mayor rendimiento, no se conservó la contraseña de su cuenta.\n\n\Para volver a acceder al área privada, deberá crear una nueva contraseña para su cuenta, introduciendo la dirección de correo electrónico con la que accede habitualmente al área de inversores.",
  },
  "migratedUserPasswordResetPage.buttonTextForgot": {
    it: "Invia",
    en: "Send",
    de: "Senden",
    fr: "Envoyer",
    es: "Enviar",
  },
  "migratedUserPasswordResetPage.buttonTextLogin": {
    it: "O torna alla pagina di accesso",
    en: "Or go Back to the login page",
    de: "Oder gehen Sie zurück zur Anmeldeseite",
    fr: "Ou retournez à la page de connexion",
    es: "O volver a la página de inicio de sesión",
  },
  "migratedUserPasswordResetPage.errorForgot": {
    it: "Questa email non esiste, provane una valida!",
    en: "This e-mail does not exists, please try a valid one!",
    de: "Diese E-Mail existiert nicht, bitte versuchen Sie es mit einer gültigen!",
    fr: "Cet email n'existe pas, essayez un email valide!",
    es: "Este correo electrónico no existe, ¡intente con uno válido!",
  },
  "migratedUserPasswordResetPage.successTitle": {
    it: "Grazie!",
    en: "Thank you!",
    de: "Vielen Dank!",
    fr: "Merci!",
    es: "¡Gracias!",
  },
  "migratedUserPasswordResetPage.successDescription": {
    it: "A breve riceverai le istruzioni per il recupero della password",
    en: "You will receive password recovery instructions shortly.",
    de: "Sie erhalten in Kürze eine Anleitung zur Wiederherstellung des Passworts.",
    fr: "Vous recevrez sous peu des instructions pour la récupération de votre mot de passe.",
    es: "En breve recibirá las instrucciones para recuperar la contraseña.",
  },
  "migratedUserPasswordResetPage.successButtonTextLogin": {
    it: "Torna alla pagina di accesso",
    en: "Back to the login page",
    de: "Zurück zur Anmeldeseite",
    fr: "Retour à la page d’accès",
    es: "Volver a la página de inicio de sesión",
  },
  "toBeApprovedPage.title": {
    it: "Grazie!",
    en: "Thank you!",
    de: "Vielen Dank!",
    fr: "Merci!",
    es: "¡Gracias!",
  },
  "toBeApprovedPage.description": {
    it: "La tua richiesta di accesso è stata inviata con successo. Riceverai notifica via mail non appena verrà approvata.",
    en: "Your login request has been sent successfully. You will be notified by email as soon as it is approved.",
    de: "Ihre Login-Anfrage wurde erfolgreich gesendet. Sie werden per E-Mail benachrichtigt, sobald es genehmigt wurde.",
    fr: "Votre demande d'accès a été soumise avec succès. Vous serez informé par e-mail dès qu'il aura été approuvé.",
    es: "Su solicitud de acceso se ha enviado correctamente. Se le notificará por correo electrónico en cuanto se haya aprobado.",
  },
  "toBeApprovedPage.backButton": {
    it: "Torna alla pagina di accesso",
    en: "Back to the login page",
    de: "Zurück zur Anmeldeseite",
    fr: "Retour à la page d’accès",
    es: "Volver a la página de inicio de sesión",
  },
  "emailVerifiedPage.title": {
    it: "Grazie!",
    en: "Thank you!",
    de: "Vielen Dank!",
    fr: "Merci!",
    es: "¡Gracias!",
  },
  "emailVerifiedPage.description": {
    it: "La tua mail è stata verificata, ma il tuo account non è stato ancora approvato. Riceverai notifica via mail non appena verrà approvato.",
    en: "Your email has been verified, but your account has not been approved yet. You will be notified by email once it will be approved.",
    de: "Ihre E-Mail wurde überprüft, aber Ihr Konto wurde noch nicht genehmigt. Sie werden per E-Mail benachrichtigt, sobald es genehmigt wurde.",
    fr: "Votre e-mail a été vérifié, mais votre compte n'a pas encore été approuvé. Vous serez informé par e-mail dès qu'il aura été approuvé.",
    es: "Su correo electrónico ha sido verificado, pero su cuenta aún no ha sido aprobada. Se le notificará por correo electrónico en cuanto se haya aprobado.",
  },
  "emailVerifiedPage.backButton": {
    it: "Torna alla pagina di accesso",
    en: "Back to the login page",
    de: "Zurück zur Anmeldeseite",
    fr: "Retour à la page d’accès",
    es: "Volver a la página de inicio de sesión",
  },
  "profilePage.logout": {
    it: "Esci",
    en: "Logout",
    de: "Ausloggen",
    fr: "Sortie",
    es: "Salir",
  },
  "profilePage.buttonTextSave": {
    it: "Salva le modifiche",
    en: "Save changes",
    de: "Änderungen speichern",
    fr: "Sauvegarder les changements",
    es: "Guardar cambios",
  },
  "profilePage.successTitle": {
    it: "Grazie!",
    en: "Thank you!",
    de: "Vielen Dank!",
    fr: "Merci!",
    es: "¡Gracias!",
  },
  "profilePage.successDescription": {
    it: "I tuoi dati personali sono aggiornati.",
    en: "Your personal data are updated.",
    de: "Ihre persönlichen Daten werden aktualisiert.",
    fr: "Vos données personnelles sont à jour.",
    es: "Sus datos personales están actualizados.",
  },
  "profilePage.successButtonTextIndex": {
    it: "Torna alla pagina Investitori",
    en: "Back to the Investor page",
    de: "Zurück zur Investorenseite",
    fr: "Retour à la page Investisseurs",
    es: "Volver a la página Inversores",
  },
  "jobsPage.filters.country": {
    it: "Nazione",
    en: "Country",
    de: "Land",
    fr: "Nation",
    es: "Nación",
  },
  "wishlist.emptyTitle": {
    it: "Nessun prodotto selezionato",
    en: "No products selected",
    de: "Keine Produkte ausgewählt",
    fr: "Pas de produit sélectionné",
    es: "Ningún producto seleccionado",
  },
  "wishlist.emptySubTitle": {
    it: "Seleziona un prodotto dal catalogo per inviare una richiesta di preventivo",
    en: "Select a product from the catalog to send a request for a quote",
    de: "Wählen Sie ein Produkt aus dem Katalog aus, um eine Angebotsanfrage zu senden",
    fr: "Sélectionnez un produit dans le catalogue pour envoyer une demande de devis.",
    es: "Seleccionar un producto del catálogo para enviar una solicitud de presupuesto",
  },
  "wishlist.emptyButtonText": {
    it: "Torna al catalogo",
    en: "Go back to the catalog",
    de: "Zurück zum Katalog",
    fr: "Retour au catalogue",
    es: "Volver al catálogo",
  },
  "wishlist.successTitle": {
    it: "Grazie!",
    en: "Thank you!",
    de: "Vielen Dank!",
    fr: "Merci!",
    es: "¡Gracias!",
  },
  "wishlist.successSubTitle": {
    it: "Il nostro team ti contatterà presto.",
    en: "Our team will contact you soon.",
    de: "Unser Team wird Sie bald kontaktieren.",
    fr: "Notre équipe vous contactera bientôt.",
    es: "Nuestro equipo se pondrá en contacto con usted en breve.",
  },
  "wishlist.successButtonText": {
    it: "Torna al catalogo",
    en: "Go back to the catalog",
    de: "Zurück zum Katalog",
    fr: "Retour au catalogue",
    es: "Volver al catálogo",
  },
  "wishlist.selectedProducts": {
    it: "Prodotti selezionati",
    en: "Selected products",
    de: "Ausgewählte Produkte",
    fr: "Produits sélectionnés",
    es: "Productos seleccionados",
  },
  "wishlist.removeProduct": {
    it: "Rimuovi",
    en: "Remove",
    de: "Entfernen",
    fr: "Supprimer",
    es: "Eliminar",
  },
  "wishlist.selectQuantity": {
    it: "Seleziona la quantità",
    en: "Select quantity",
    de: "Wählen Sie die Menge",
    fr: "Sélectionnez la quantité",
    es: "Seleccionar cantidad",
  },
  "wishlist.quantity1": {
    it: "Meno di 10.000",
    en: "Less than 10.000",
    de: "Weniger als 10.000",
    fr: "Moins de 10.000",
    es: "Menos de 10.000",
  },
  "wishlist.quantity2": {
    it: "Da 10.000 a 50.000",
    en: "10.000 to 50.000",
    de: "Von 10.000 bis 50.000",
    fr: "De 10.000 à 50.000",
    es: "De 10.000 a 50.000",
  },
  "wishlist.quantity3": {
    it: "Da 50.000 a 100.000",
    en: "50.000 a 100.000",
    de: "Von 50.000 bis 100.000",
    fr: "De 50.000 à 100.000",
    es: "De 50.000 a 100.000",
  },
  "wishlist.quantity4": {
    it: "Da 100.000 a 500.000",
    en: "100.000 to 500.000",
    de: "Von 100.000 bis 500.000",
    fr: "De 100.000 à 500.000",
    es: "De 100.000 a 500.000",
  },
  "wishlist.quantity5": {
    it: "Da 500.000 a 1.000.000",
    en: "500.000 to 1.000.000",
    de: "Von 500.000 bis 1.000.000",
    fr: "De 500.000 à 1.000.000",
    es: "De 500.000 a 1.000.000",
  },
  "wishlist.quantity6": {
    it: "Da 1.000.000 a 5.000.000",
    en: "1.000.000 to 5.000.000",
    de: "Von 1.000.000 bis 5.000.000",
    fr: "De 1.000.000 à 5.000.000",
    es: "De 1.000.000 a 5.000.000",
  },
  "wishlist.quantity7": {
    it: "Più di 5.000.000",
    en: "More than 5.000.000",
    de: "Mehr als 5.000.000",
    fr: "Plus de 5.000.000",
    es: "Más de 5.000.000",
  },
  "contactPage.successTitle": {
    it: "Grazie!",
    en: "Thank you!",
    de: "Vielen Dank!",
    fr: "Merci!",
    es: "¡Gracias!",
  },
  "contactPage.successSubTitle": {
    it: "Il nostro team ti contatterà presto.",
    en: "Our team will contact you soon.",
    de: "Unser Team wird Sie bald kontaktieren.",
    fr: "Notre équipe vous contactera bientôt.",
    es: "Nuestro equipo se pondrá en contacto con usted en breve.",
  },
  "contactPage.successButtonText": {
    it: "Torna ai contatti",
    en: "Go back to the contacts",
    de: "Gehen Sie zurück zu den Kontakten",
    fr: "Retour aux contacts",
    es: "Volver a contactos",
  },
  "contactPage.loadingButtonSubmit": {
    it: "Inviando",
    en: "Sending",
    de: "Senden",
    fr: "Envoyer",
    es: "Envío",
  },
  "investor.financeCalendar": {
    it: "Calendario finanziario",
    en: "Financial calendar",
    de: "Finanzkalender",
    fr: "Calendrier financier",
    es: "Calendario financiero",
  },
  "investor.documents": {
    it: "Archivio documenti",
    en: "Document Archive",
    de: "Archiv der Dokumente",
    fr: "Archives de documents",
    es: "Archivo de documentos",
  },
  "investor.filters.category": {
    it: "Categoria",
    en: "Category",
    de: "Kategorie",
    fr: "Catégorie",
    es: "Categoría",
  },
  "investor.filters.quarter": {
    it: "Trimestre",
    en: "Quarterly",
    de: "Vierteljährlich",
    fr: "Trimestre",
    es: "Trimestre",
  },
  "investor.filters.year": {
    it: "Anno",
    en: "Year",
    de: "Jahr",
    fr: "Année",
    es: "Año",
  },
  "investor.time": {
    it: "Orario",
    en: "Hour",
    de: "Stunde",
    fr: "Horaire",
    es: "Horario",
  },
  "filters.removeAll": {
    it: "Rimuovi tutti",
    en: "Remove all",
    de: "Alle entfernen",
    fr: "Supprimer tous",
    es: "Eliminar todo",
  },
  "resetPasswordPage.successTitle": {
    it: "Password reimpostata",
    en: "Password reset",
    de: "Passwort zurücksetzen",
    fr: "Réinitialisation du mot de passe",
    es: "La contraseña se ha restablecido",
  },
  "resetPasswordPage.successButton": {
    it: "Accedi",
    en: "Sign in",
    de: "Einloggen",
    fr: "Se connecter",
    es: "Acceder",
  },
  "resetPasswordPage.title": {
    it: "Reimposta la password",
    en: "Reset password",
    de: "Passwort zurücksetzen",
    fr: "Réinitialiser le mot de passe",
    es: "Restablecer contraseña",
  },
  "resetPasswordPage.button": {
    it: "Invia",
    en: "Send",
    de: "Senden",
    fr: "Envoyer",
    es: "Enviar",
  },
  "resetPasswordPage.inputPassword": {
    it: "Password",
    en: "Password",
    de: "Password",
    fr: "Mot de passe",
    es: "Contraseña",
  },
  "resetPasswordPage.inputPasswordConf": {
    it: "Conferma password",
    en: "Password confirmation",
    de: "Bestätigung des Kennworts",
    fr: "Confirmation du mot de passe",
    es: "Confirmación de contraseña",
  },
  "signupPage.successTitle": {
    it: "Grazie",
    en: "Thank you",
    de: "Danke",
    fr: "Merci",
    es: "Gracias",
  },
  "signupPage.successDescription": {
    it: "Ti abbiamo inviato una email di conferma",
    en: "We have sent you a confirmation email",
    de: "Wir haben Ihnen eine Bestätigungs-E-Mail gesendet",
    fr: "Nous vous avons envoyé un e-mail de confirmation",
    es: "Le hemos enviado un correo electrónico de confirmación",
  },
  "signupPage.successButtonTextLogin": {
    it: "Torna alla pagina di accesso",
    en: "Back to the login page",
    de: "Zurück zur Anmeldeseite",
    fr: "Retour à la page d’accès",
    es: "Volver a la página de inicio de sesión",
  },
  "errorPage.Title": {
    it: "Ci spiace",
    en: "Apologies",
    de: "Entschuldigung",
    fr: "Désolé",
    es: "Lo sentimos",
  },
  "errorPage.Description": {
    it: "Qualcosa è andato storto.",
    en: "Something went wrong",
    de: "Etwas ist schief gelaufen",
    fr: "Quelque chose a mal tourné.",
    es: "Algo salió mal.",
  },
  "errorPage.ButtonTextLogin": {
    it: "Torna alla pagina di accesso",
    en: "Back to the login page",
    de: "Zurück zur Anmeldeseite",
    fr: "Retour à la page d’accès",
    es: "Volver a la página de inicio de sesión",
  },
  "position.apply": {
    it: "Candidati ora",
    en: "Apply now",
    de: "Jetzt bewerben",
    fr: "Postulez maintenant",
    es: "Solicite ahora",
  },
  "infoTooltip.Title": {
    it: "I prodotti ecopositive",
    en: "Eco-positive products",
    de: "Umweltfreundliche Produkte",
    fr: "Produits éco-positifs",
    es: "Productos ecopositivos",
  },
  "infoTooltip.Description": {
    it: "EcoPositive è la gamma che raccoglie tutte le nostre soluzioni eco-friendly e include un vasto assortimento di prodotti in vetro e plastiche sostenibili.",
    en: "EcoPositive is the range that brings together all our eco-friendly solutions and includes a wide assortment of sustainable glass and plastic products.",
    de: "EcoPositive ist das Sortiment, das alle unsere umweltfreundlichen Lösungen zusammenfasst und ein breites Sortiment an nachhaltigen Glas- und Kunststoffprodukten umfasst.",
    fr: "EcoPositive est la gamme qui regroupe toutes nos solutions écologiques et comprend un large assortiment de produits durables en verre et en plastique.",
    es: "EcoPositive es la gama que reúne todas nuestras soluciones ecológicas e incluye un amplio surtido de productos sostenibles de vidrio y plástico.",
  }
};

const isLocaleKey = (locale: string): locale is L_KEYS => {
  return ["it", "en", "de", "fr", "es"].includes(locale);
};

export const useTranslation = (
  key: T_KEYS,
  locale: string
): string | undefined => {
  if (isLocaleKey(locale)) {
    return t[key][locale];
  } else {
    console.error("Unrecognized locale:", locale);
  }
};
