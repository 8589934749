import { SVGProps } from "react";

type Props = SVGProps<SVGSVGElement>;

/**
 * Optimized with https://jakearchibald.github.io/svgomg/
 */
const SVGDownload: React.FC<Props> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M20.501 15a.5.5 0 0 0-1 0h1Zm-.5 3h.5-.5Zm-2 2v-.5.5Zm-12 0v.5-.5Zm-2-2h-.5.5Zm.5-3a.5.5 0 0 0-1 0h1Zm3.854-4.354a.5.5 0 0 0-.708.708l.708-.708ZM12 15l-.354.354a.5.5 0 0 0 .707 0L12.002 15Zm4.354-3.646a.5.5 0 0 0-.708-.708l.708.708ZM12.5 3a.5.5 0 0 0-1 0h1Zm7 12v3h1v-3h-1Zm-1.5 4.5h-12v1h12v-1ZM4.501 18v-3h-1v3h1Zm1.5 1.5a1.5 1.5 0 0 1-1.5-1.5h-1a2.5 2.5 0 0 0 2.5 2.5v-1Zm13.5-1.5a1.5 1.5 0 0 1-1.5 1.5v1a2.5 2.5 0 0 0 2.5-2.5h-1ZM7.647 11.354l4 4 .707-.708-4-4-.707.708Zm4.707 4 4-4-.707-.708-4 4 .707.708Zm.147-.354V3h-1v12h1Z"
    />
  </svg>
);

export default SVGDownload;
