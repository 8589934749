import { SVGProps } from "react";

type Props = SVGProps<SVGSVGElement>;

/**
 * Optimized with https://jakearchibald.github.io/svgomg/
 */
const SVGUser: React.FC<Props> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M7 7C7.79565 7 8.55871 6.68393 9.12132 6.12132C9.68393 5.55871 10 4.79565 10 4C10 3.20435 9.68393 2.44129 9.12132 1.87868C8.55871 1.31607 7.79565 1 7 1C6.20435 1 5.44129 1.31607 4.87868 1.87868C4.31607 2.44129 4 3.20435 4 4C4 4.79565 4.31607 5.55871 4.87868 6.12132C5.44129 6.68393 6.20435 7 7 7V7ZM7 8.5C4.998 8.5 1 9.505 1 11.5V12.25C1 12.663 1.338 13 1.75 13H12.25C12.663 13 13 12.663 13 12.25V11.5C13 9.505 9.002 8.5 7 8.5Z"
        stroke="#C11113"
      />
    </svg>
  );
};

export default SVGUser;
