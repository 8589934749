import styled from "styled-components";
import { resetButton } from "@/lib/styles";
import { smallLabelTypographicStyle } from "@/lib/styles/helpers";

export const Container = styled.nav`
  display: none;
  ${({ theme }) => theme.mediaQueries.laptop} {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
`;

export const Button = styled.button<{
  $isActive?: boolean;
  $menuIsOpen?: boolean;
}>`
  ${resetButton}
  cursor: pointer;
  text-transform: uppercase;
  height: 2.375rem;
  font-family: ${({ theme }) => theme.fontFamilies.secondary};
  ${smallLabelTypographicStyle}
  font-weight: 700;

  transition: color 0.2s ease-out;
  &:hover {
    color: ${({ $menuIsOpen, theme }) =>
      $menuIsOpen ? theme.colors.white : theme.colors.red};
  }
  color: ${({ $isActive, $menuIsOpen, theme }) =>
    $menuIsOpen
      ? $isActive
        ? theme.colors.white
        : theme.colors.middleGrey
      : $isActive
      ? theme.colors.red
      : theme.colors.black};
`;

export const Anchor = styled(Button).attrs({
  as: "a",
})`
  display: flex;
  align-items: center;
  text-decoration: none;
`;
