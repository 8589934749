import { SVGProps } from "react";

type Props = SVGProps<SVGSVGElement> & {
  title?: string;
};

/**
 * Optimized with https://jakearchibald.github.io/svgomg/
 */
const SVGCross: React.FC<Props> = (props) => {
  const { title, ...rest } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 18 18"
      {...rest}
    >
      <title>{title}</title>
      <path
        fill="currentColor"
        d="M17.354 1.354a.5.5 0 1 0-.708-.708l.707.708ZM.646 16.646a.5.5 0 0 0 .708.708l-.708-.708Zm.708-16a.5.5 0 0 0-.708.708l.708-.708Zm15.292 16.708a.5.5 0 0 0 .708-.708l-.708.708Zm0-16.708-16 16 .708.708 16-16-.708-.708Zm-16 .708 16 16 .708-.708-16-16-.708.708Z"
      />
    </svg>
  );
};

export default SVGCross;
