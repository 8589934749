import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
`;

export const Content = styled.main`
  /** 
   * Be careful amending padding-top as some components assume this exists and 
   * have theme.space.headerMinHeight size
   */
  padding-top: ${({ theme }) => theme.space.headerMinHeight};
  min-height: calc(100vh - ${({ theme }) => theme.space.headerMinHeight});

  &.animation-enter {
    opacity: 0;
  }
  &.animation-enter-active {
    opacity: 1;
    transition: opacity 300ms;
  }
  &.animation-exit {
    opacity: 1;
  }
  &.animation-exit-active {
    opacity: 0;
    transition: opacity 300ms;
  }
`;
