import SVGUser from "@/lib/svg/SVGUser";
import { NextPage } from "next";
import { useTranslation } from "@/lib/translations";
import {
  Container,
  LogoutButton,
  UserIconWrapper,
  Username,
  UserWapper,
} from "./styles";
import { useAuth } from "@/lib/hooks/useAuth";
import { logout } from "@/lib/context/auth";
import router, { useRouter } from "next/router";

type Props = {};

const UserPage: NextPage<Props> = () => {
  const { user, clearUser } = useAuth();
  const router = useRouter();
  const { locale = "it" } = router;
  const t = {
    logout: useTranslation("profilePage.logout", locale) || "",
  };

  return (
    <Container>
      <UserWapper onClick={() => router.push("/investors/profile")}>
        <UserIconWrapper>
          <SVGUser />
        </UserIconWrapper>
        <Username>{user?.email}</Username>
      </UserWapper>
      <LogoutButton
        onClick={() => {
          logout();
          clearUser();
        }}
      >
        {t.logout}
      </LogoutButton>
    </Container>
  );
};

export default UserPage;
