import { SVGProps } from "react";

type Props = SVGProps<SVGSVGElement>;

/**
 * Optimized with https://jakearchibald.github.io/svgomg/
 */
const SVGPlus: React.FC<Props> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    {...props}
  >
    <path
      d="M1 8.5C0.723858 8.5 0.5 8.72386 0.5 9C0.5 9.27614 0.723858 9.5 1 9.5V8.5ZM17 9.5C17.2761 9.5 17.5 9.27614 17.5 9C17.5 8.72386 17.2761 8.5 17 8.5V9.5ZM9.5 1C9.5 0.723858 9.27614 0.5 9 0.5C8.72386 0.5 8.5 0.723858 8.5 1H9.5ZM8.5 17C8.5 17.2761 8.72386 17.5 9 17.5C9.27614 17.5 9.5 17.2761 9.5 17H8.5ZM1 9.5H17V8.5H1V9.5ZM8.5 1V17H9.5V1H8.5Z"
      fill="#C11113"
    />
  </svg>
);

export default SVGPlus;
