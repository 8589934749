import React from "react";
import { useAppSelector } from "@/lib/redux/hooks";
import DesktopMenu from "./DesktopMenu";
import DesktopNav from "./DesktopNav";
import HomeLink from "./HomeLink";
import MenuButton from "./MenuButton";
import MobileMenu from "./MobileMenu";
import { Container, Controls } from "./styles";
import { MainLayoutQuery } from "@/lib/sanity/queries";
import { LanguagePanel } from "./LanguagePanel/LanguagePanel";
import WishlistButton from "./WishlistButton";

type Props = {
  navigation: MainLayoutQuery["header"]["navigation"];
};

const DefaultHeader: React.FC<Props> = (props) => {
  const { navigation } = props;
  const desktopMenuIsOpen = useAppSelector((state) => state.desktopMenu.isOpen);

  return (
    <>
      <Container $desktopMenuIsOpen={desktopMenuIsOpen}>
        <HomeLink />
        {navigation?.items && <DesktopNav items={navigation.items} />}
        <Controls>
          <LanguagePanel $desktopMenuIsOpen={desktopMenuIsOpen} />
          <WishlistButton />
          <MenuButton />
        </Controls>
      </Container>
      {navigation?.items && (
        <>
          <DesktopMenu items={navigation.items} />
          <MobileMenu items={navigation.items} />
        </>
      )}
    </>
  );
};

export default DefaultHeader;
