import styled, { css } from "styled-components";
import { h1, h2 } from "@/lib/styles/helpers";
import { myTheme } from "@/lib/styles/theme";

// checked
export const H1 = styled.h1`
  ${h1};
`;

// checked
export const H2 = styled.h2`
  ${h2};
`;

// checked
export const H3 = styled.h3`
  font-family: ${({ theme }) => theme.fontFamilies.primary};
  font-size: 2.5rem;
  line-height: 1;
  ${({ theme }) => theme.mediaQueries.tablet} {
    font-size: 3rem;
  }
  ${({ theme }) => theme.mediaQueries.laptop} {
    font-size: 4rem;
  }
  ${({ theme }) => theme.mediaQueries.desktop} {
    font-size: 5rem;
  }
`;

// checked
export const H4 = styled.h4`
  font-family: ${({ theme }) => theme.fontFamilies.primary};
  font-size: 2rem;
  line-height: 1.2;

  ${({ theme }) => theme.mediaQueries.laptop} {
    font-size: 2.5rem;
  }
`;

export const H5 = styled.h5`
  font-family: ${({ theme }) => theme.fontFamilies.primary};
  font-size: 1.5rem;
  line-height: 1.2;

  ${({ theme }) => theme.mediaQueries.laptop} {
    font-size: 2rem;
  }
`;

// checked
export const Q = styled.q`
  font-size: 2rem;
  line-height: 1;
  font-weight: 300;
  ${({ theme }) => theme.mediaQueries.laptop} {
    font-size: 3rem;
    line-height: 1.15;
  }
`;

export const propertiesOfP = css`
  font-family: ${myTheme.fontFamilies.primary};
  font-size: 1.25rem;
  line-height: 1.2;
  font-weight: 400;
  ${myTheme.mediaQueries.tablet} {
    font-size: 1.5rem;
  }
`;

// checked
export const P = styled.p`
  ${(_) => propertiesOfP}
`;

export const Small = styled.small`
  font-family: ${({ theme }) => theme.fontFamilies.secondary};
  font-size: 0.875rem;
  line-height: 1.25;
`;

export const Strong = styled.strong`
  font-weight: 600;
`;

export const A = styled.a`
  color: ${({ theme }) => theme.colors.black};
  text-decoration: underline;
`;
