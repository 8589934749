export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_ID;

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url: string) => {
  if ((window as any).gtag === "function") {
    (window as any).gtag('consent', 'default', {
      ad_personalization: "denied",
      ad_storage: "denied",
      ad_user_data: "denied",
      analytics_storage: "denied",
      functionality_storage: "denied",
      personalization_storage: "denied",
      security_storage: "granted",
      wait_for_update: 500,
    });
    (window as any).gtag("config", GA_TRACKING_ID, {
      page_path: url,
    });
    (window as any).gtag("set", "ads_data_redaction", true);
    (window as any).gtag("set", "url_passthrough", true);
  }
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({
  action,
  category,
  label,
  value,
  non_interaction,
}: any) => {
  if ((window as any).gtag === "function") {
    (window as any).gtag("event", action, {
      event_category: category,
      event_label: label,
      value: value,
      non_interaction,
    });
  }
};


