import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

export type DocumentFilter = {
  id: string;
  values: string[];
  selectedValues: string[];
};

export const documentFilterAdapter = createEntityAdapter<DocumentFilter>({
  // Assume IDs are stored in a field other than `filter.id`
  selectId: (filter) => filter.id,
  // Keep the "all IDs" array sorted based on filter titles
  //sortComparer: (a, b) => a.title.localeCompare(b.title),
});

const documentFilters = createSlice({
  name: "documentFilters",
  initialState: documentFilterAdapter.getInitialState(),
  reducers: {
    // Can pass adapter functions directly as case reducers.  Because we're passing this
    // as a value, `createSlice` will auto-generate the `filterAdded` action type / creator
    setAllDocFilters: documentFilterAdapter.setAll,
    addDocFilter: documentFilterAdapter.addOne,
    removeDocFilter: documentFilterAdapter.removeOne,
    removeAllDocFilters: documentFilterAdapter.removeAll,
    updateDocFilter: documentFilterAdapter.updateOne,
    updateManyDocFilters: documentFilterAdapter.updateMany,
  },
});

export const {
  setAllDocFilters,
  addDocFilter,
  removeDocFilter,
  removeAllDocFilters,
  updateDocFilter,
  updateManyDocFilters,
} = documentFilters.actions;

export default documentFilters.reducer;
