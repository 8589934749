import { SVGProps } from "react";

type Props = SVGProps<SVGSVGElement>;

/**
 * Optimized with https://jakearchibald.github.io/svgomg/
 */
const SVGDocument: React.FC<Props> = (props) => (
  <svg
    /*  width="18"
    height="24" */
    viewBox="0 0 18 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M17.417 5.741v17.74H.72V.522h11.478l5.218 5.218Z"
      stroke="#C11113"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.852 9.915h1.044M17.417 5.74H12.2V.523l5.217 5.218ZM6.982 19.306h7.304-7.304Zm0-3.13h7.304-7.304Zm0-3.131h7.304-7.304Zm0-3.13h7.304-7.304Zm-3.13 9.39h1.044-1.044Zm0-3.13h1.044-1.044Zm0-3.13h1.044-1.044Z"
      stroke="#C11113"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SVGDocument;
