import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { z } from "zod";
import { uniqueId } from "lodash";

const lineSchema = z.object({
  id: z.string().min(1),
  productID: z.string().min(1),
  quantity: z.string().optional(),
  productName: z.string().optional(),
});

type Line = z.infer<typeof lineSchema>;

export const wishlistSchema = z.object({
  lines: z.array(lineSchema),
});

type WishlistState = z.infer<typeof wishlistSchema>;

const initialState: WishlistState = {
  lines: [],
};

export const WishlistSlice = createSlice({
  name: "Wishlist",
  initialState,
  reducers: {
    createWishlist(state, action: PayloadAction<WishlistState>) {
      state.lines = action.payload.lines;
    },
    wishlistLineAdd(state, action: PayloadAction<Omit<Line, "id">>) {
      const exist = state.lines.find(
        (line) => line.productID == action.payload.productID
      );

      if (!exist) {
        const line = {
          id: uniqueId("wishlist_"), // TODO
          ...action.payload,
        };
        state.lines = [line, ...state.lines];
      }
    },
    wishlistLineRemove(state, action: PayloadAction<string>) {
      const lines = state.lines.filter(
        (line) => line.productID != action.payload
      );
      state.lines = lines; // TODO
    },
    wishlistLineUpdate(state, action: PayloadAction<Omit<Line, "id">>) {
      let newState = [...state.lines];
      newState = newState.map((item: any) => {
        if (item.productID == action.payload.productID) {
          item.quantity = action?.payload?.quantity;
          return item;
        }

        return item;
      });
      state.lines = newState; // TODO
    },
  },
});

export const {
  createWishlist,
  wishlistLineAdd,
  wishlistLineRemove,
  wishlistLineUpdate,
} = WishlistSlice.actions;

export default WishlistSlice.reducer;

// export const urlQueryToWishlistFilters = (urlQuery: ParsedUrlQuery) => {
//   const filters: Filters = {};
//   // Populate filters
//   for (const key in urlQuery) {
//     const value = urlQuery[key];
//     if (value instanceof Array) {
//       filters[key] = value;
//     } else if (value) {
//       filters[key] = [value];
//     }
//   }
//   return filters;
// };

// export const wishlistFiltersToUrlQuery = (filters: Filters) => {
//   const urlQuery: ParsedUrlQuery = {};
//   for (const key in filters) {
//     const value = filters[key];
//     if (value) {
//       urlQuery[key] = value;
//     }
//   }
//   return urlQuery;
// };
