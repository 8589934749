import { MainLayoutQuery } from "@/lib/sanity/queries";
import dynamic from "next/dynamic";
import HomeLink from "./HomeLink";
import Navigation from "./Navigation";
import { Container } from "./styles";

// TODO: understand why this component throws on SSR
const Content = dynamic(() => import("./Content"), { ssr: false });

type Props = {
  navigation: MainLayoutQuery["footer"]["navigation"];
  content?: MainLayoutQuery["footer"]["content"];
};

const Footer: React.FC<Props> = (props) => {
  const { navigation, content } = props;
  return (
    <Container>
      <HomeLink />
      <Navigation {...navigation} />
      {content && <Content blocks={content} />}
    </Container>
  );
};

export default Footer;
