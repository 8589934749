import styled from "styled-components";

export const Container = styled.div`
  position: fixed;
  inset: 0;
  z-index: 11;
  overflow-y: scroll;

  background-color: ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.white};

  opacity 0.0;
  display: none;

  &.animation-enter {
    display: block;
    opacity 0.0;
  }
  &.animation-enter-done {
    display: block;
    opacity: 1;
  }
  &.animation-enter-active {
    display: block;
    opacity: 1;
    transition: opacity 100ms;
  }
  &.animation-exit {
    display: block;
    opacity: 1;
  }
  &.animation-exit-active {
    display: block;
    opacity 0.0;
    transition: opacity 100ms;
  }
  &.animation-exit-done {
    opacity 0.0;
    display: none;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    display: none;
    &.animation-enter,
    &.animation-enter-done,
    &.animation-enter-active,
    &.animation-exit,
    &.animation-exit-active {
      display: none;
    }
  }
`;
