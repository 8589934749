import { SVGProps } from "react";

type Props = SVGProps<SVGSVGElement>;

/**
 * Optimized with https://jakearchibald.github.io/svgomg/
 */
const SVGChevronDown: React.FC<Props> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 46 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M2.375.293A1 1 0 0 0 .96 1.707L2.375.293ZM23 22.333l-.707.707a1 1 0 0 0 1.414 0L23 22.333ZM45.04 1.707A1 1 0 0 0 43.627.293l1.414 1.414Zm-44.08 0L22.293 23.04l1.414-1.414L2.375.293.96 1.707ZM23.707 23.04 45.04 1.707 43.627.293 22.294 21.626l1.414 1.414Z"
      />
    </svg>
  );
};

export default SVGChevronDown;
