import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useRef } from "react";
import { Badge, ContactBadgeContainer } from "./styles";
import SVGMail from "@/lib/svg/SVGMail";

export const RotatingContactBadge: React.FC = () => {
  const router = useRouter();
  const { locale, pathname } = router;
  const badgeRef = useRef<HTMLAnchorElement>(null);

  return (
    <ContactBadgeContainer $visible={pathname !== "/contacts"}>
      <Link href="/contacts" passHref>
        <Badge ref={badgeRef}>
          <SVGMail />
        </Badge>
      </Link>
    </ContactBadgeContainer>
  );
};

export default RotatingContactBadge;
