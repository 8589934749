import { css } from "styled-components";

export const bigLabelTypographicStyle = css`
  font-family: ${({ theme }) => theme.fontFamilies.secondary};
  font-size: 1.25rem;
  line-height: 1.2;
`;

export const smallLabelTypographicStyle = css`
  font-family: ${({ theme }) => theme.fontFamilies.secondary};
  font-size: 0.875rem;
  line-height: 22.4px;
  font-weight: 400;
`;

export const pageMarginsLeft = css`
  margin-left: 1rem;

  ${({ theme }) => theme.mediaQueries.tablet} {
    margin-left: 1.5rem;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    margin-left: 2.5rem;
  }
`;

export const pageMarginsRight = css`
  margin-right: 1rem;

  ${({ theme }) => theme.mediaQueries.tablet} {
    margin-right: 1.5rem;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    margin-right: 2.5rem;
  }
`;

export const pageTop = css`
  padding-top: 5rem;
`;

export const pageTopBackButton = css`
  padding-top: 2.5rem;
`;

export const pageBottom = css`
  padding-bottom: 5rem;
`;

export const pageMaxWidth = css`
  ${({ theme }) => theme.mediaQueries.desktop} {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
    max-width: 100rem;
  }
  @media screen and (min-width: 105rem) {
    margin-left: auto;
    margin-right: auto;
    max-width: 100rem;
  }
`;

export const pageMargins = css`
  ${pageMarginsLeft};
  ${pageMarginsRight};
  ${pageMaxWidth};
`;

export const hideScrollbar = css`
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari and Opera */
  }
`;

export const debug = css`
  border: 1px solid red;
  ${({ theme }) => theme.mediaQueries.tablet} {
    border: 1px solid blue;
  }
  ${({ theme }) => theme.mediaQueries.laptop} {
    border: 1px solid orange;
  }
  ${({ theme }) => theme.mediaQueries.desktop} {
    border: 1px solid green;
  }
`;

export const h1 = css`
  font-family: ${({ theme }) => theme.fontFamilies.primary};
  font-size: 4rem;
  line-height: 0.9;
  ${({ theme }) => theme.mediaQueries.tablet} {
    font-size: 7rem;
  }
  ${({ theme }) => theme.mediaQueries.laptop} {
    line-height: 0.8;
  }
  ${({ theme }) => theme.mediaQueries.desktop} {
    font-size: 7.25rem;
  }
`;

// checked
export const h2 = css`
  font-family: ${({ theme }) => theme.fontFamilies.primary};
  font-size: 3.5rem;
  line-height: 0.9;
  ${({ theme }) => theme.mediaQueries.tablet} {
    font-size: 5rem;
  }
  ${({ theme }) => theme.mediaQueries.laptop} {
    font-size: 5.5rem;
  }
  ${({ theme }) => theme.mediaQueries.desktop} {
    font-size: 6rem;
  }
`;
