import styled from "styled-components";
import { resetButton } from "./reset";
import { bigLabelTypographicStyle } from "@/lib/styles/helpers";

export const Button = styled.button`
  ${resetButton}
  display: flex;
  cursor: pointer;
  text-decoration: none;
  height: max-content;
  gap: 0.5em;

  ${bigLabelTypographicStyle}

  &[disabled] {
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
  }

  transition-property: all;
  transition-duration: 200ms;
`;

export const ButtonBoxedPrimary = styled(Button)<{
  $hover?: boolean;
}>`
  background-color: ${({ theme }) => theme.colors.red};
  color: ${({ theme }) => theme.colors.white};
  padding: 1.5rem 1rem 1.5rem;
  border-radius: 0.125rem;
  justify-content: center;
  border: solid 1px ${({ theme }) => theme.colors.red};

  &:hover {
    ${({ theme, $hover = true }) => ($hover) ? `background-color: ${theme.colors.white}` : ""};
    ${({ theme, $hover = true }) => ($hover) ? `color: ${theme.colors.red}` : ""};
  }
`;

export const ButtonBoxedPrimaryNegative = styled(ButtonBoxedPrimary)<{
  $transparent?: boolean;
  $hover?: boolean;
}>`
  background-color: ${({ theme, $transparent }) =>
    $transparent ? "transparent" : theme.colors.white};
  color: ${({ theme }) => theme.colors.red};
  border: solid 1px;

  &:hover {
    ${({ theme, $hover = true }) => ($hover) ? `border: solid 1px ${theme.colors.red}` : ""};
    ${({ theme, $hover = true }) => ($hover) ? `background-color: ${theme.colors.red}` : ""};
    ${({ theme, $hover = true }) => ($hover) ? `color: ${theme.colors.white}` : ""};
  }
`;

export const ButtonUnboxedPrimary = styled(ButtonBoxedPrimary)<{
  $hover?: boolean;
}>`
  justify-content: start;
  background: none;
  color: ${({ theme }) => theme.colors.red};
  border: 0;
  border-bottom: solid 1px ${({ theme }) => theme.colors.white};
  border-radius: 0;
  ${({ theme, $hover = true }) => ($hover) ? "padding: 0 0 0.5rem" : ""};

  &:hover {
    border-bottom: solid 1px ${({ theme, $hover = true }) => $hover ? "" : "transparent"};
  }
`;

export const ButtonUnboxedPrimaryNegative = styled(ButtonUnboxedPrimary)<{
  $hover?: boolean;
}>`
  color: ${({ theme }) => theme.colors.white};
  border: 0;
  border-bottom: solid 1px transparent;

  &:hover {
    ${({ theme, $hover = true }) => ($hover) ? `background-color: ${theme.colors.red}` : ""};
    ${({ theme, $hover = true }) => ($hover) ? `color: ${theme.colors.white}` : ""};
  }
`;

export const ButtonBoxedSecondary = styled(ButtonBoxedPrimary)<{
  $hover?: boolean;
}>`
  background-color: ${({ theme }) => theme.colors.black};
  border: 0;

  &:hover {
    ${({ theme, $hover = true }) => ($hover) ? `background-color: ${theme.colors.black}` : ""};
    ${({ theme, $hover = true }) => ($hover) ? `color: ${theme.colors.darkMiddle}` : ""};
  }
`;

export const ButtonBoxedSecondaryNegative = styled(ButtonBoxedSecondary)<{
  $transparent?: boolean;
}>`
  background-color: ${({ theme, $transparent }) =>
    $transparent ? "transparent" : theme.colors.white};
  color: ${({ theme }) => theme.colors.black};
  border: solid 1px;
`;

export const ButtonUnboxedSecondary = styled(ButtonBoxedSecondary)`
  justify-content: start;
  background: none;
  color: ${({ theme }) => theme.colors.black};
`;

export const ButtonUnboxedSecondaryNegative = styled(ButtonUnboxedSecondary)`
  color: ${({ theme }) => theme.colors.white};
`;
