import styled, { DefaultTheme } from "styled-components";

const fontFamilies: DefaultTheme["fontFamilies"] = {
  primary: "Karbon",
  secondary: "Lato",
};

export const colors: DefaultTheme["colors"] = {
  red: "#C11113",
  backgroundGrey: "#F5F5F5",
  black: "#25262E",
  darkMiddle: "#666666",
  greenEco: "#6C936F",
  lightGrey: "#E6E6E6",
  middleGrey: "#AAAAAA",
  white: "#FFFFFF",
};

const shadows: DefaultTheme["shadows"] = {
  panelShadow: "0px 4px 16px rgba(0, 0, 0, 0.16)",
};

// WARNING we are using these values outside styled components as well.
const breakpoints: DefaultTheme["breakpoints"] = {
  sm: "20rem",
  md: "48rem",
  lg: "64rem",
  xl: "90rem", //1440
};

const mediaQueries: DefaultTheme["mediaQueries"] = {
  tablet: `@media screen and (min-width: ${breakpoints.md})`,
  laptop: `@media screen and (min-width: ${breakpoints.lg})`,
  desktop: `@media screen and (min-width: ${breakpoints.xl})`,
};

// WARNING we are using these values outside styled components as well.
const space: DefaultTheme["space"] = {
  headerMinHeight: "4.5rem",
  headerMaxHeight: "27rem",
};

export const myTheme: DefaultTheme = {
  fontFamilies,
  colors,
  breakpoints,
  mediaQueries,
  space,
  shadows,
};

export const ImageWrapper = styled.div<{ $hasLoaded: boolean }>`
  background: ${({ theme }) => theme.colors.lightGrey};
  position: relative;

  & > *:first-child {
    opacity: ${({ $hasLoaded }) => ($hasLoaded ? "1.0" : "0.0")} !important;
    transition: opacity 0.3s !important;
  }
`;
