import { resetButton } from "@/lib/styles";
import { smallLabelTypographicStyle } from "@/lib/styles/helpers";
import styled, { css } from "styled-components";

export const LanguagePanelContainer = styled.div`
  * {
    box-sizing: border-box;
  }
  height: 24px;
  position: relative;
`;

export const LanguageSelectButton = styled.button`
  ${resetButton}
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

export const CurrentLanguage = styled.span<{ $desktopMenuIsOpen?: boolean }>`
  ${smallLabelTypographicStyle}
  font-weight: 600;
  text-transform: uppercase;
  color: ${({ $desktopMenuIsOpen, theme }) =>
    $desktopMenuIsOpen ? theme.colors.white : theme.colors.black};
`;

export const IconWrapper = styled.div<{
  $isOpen?: boolean;
  $desktopMenuIsOpen?: boolean;
}>`
  display: grid;
  place-items: center;
  width: 24px;
  height: 24px;
  padding-bottom: 1px; // icon centering

  svg {
    transition: transform 0.1s ease-in;
    path {
      stroke: ${({ $desktopMenuIsOpen, theme }) =>
        $desktopMenuIsOpen ? theme.colors.white : theme.colors.black};
    }
  }
  ${({ $isOpen }) =>
    $isOpen &&
    css`
      svg {
        transform: rotate(180deg);
      }
    `}
`;

export const SelectorContainer = styled.div<{ $isOpen?: boolean }>`
  position: absolute;
  top: 34px; //?
  right: 0; // left in Figma but looks bad when there's no order icon on the right
  background-color: ${({theme})=> theme.colors.white};
  box-shadow: ${({ theme }) => theme.shadows.panelShadow};
  height: ${({ $isOpen }) => ($isOpen ? "auto" : "0px")};
  // TODO: animate opening
  //height: 0;
  overflow: hidden;
  transition: height 0.1s ease-in;
`;

export const SelectorInner = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 0 16px;
`;

export const LocaleButton = styled.button<{ $isActive?: boolean }>`
  ${resetButton}
  display: grid;
  place-items: center;
  padding: 17px 24px 15px 24px; // fix centering
  cursor: pointer;

  ${smallLabelTypographicStyle}
  font-weight: 600;
  text-transform: uppercase;

  color: ${({ $isActive, theme }) =>
    $isActive ? theme.colors.black : theme.colors.middleGrey};
  transition: color 0.1s ease-in;
  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.middleGrey};
  }

  // TODO check hover
  &:hover {
    color: ${({ theme }) => theme.colors.black};
  }
`;
