import { memo, PropsWithChildren, SVGProps } from "react";

type Props = PropsWithChildren<SVGProps<SVGSVGElement>>;

/**
 * Optimized with https://jakearchibald.github.io/svgomg/
 */
const SVGLens: React.FC<Props> = (props) => {
  const { children, ...rest } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 22 22"
      {...rest}
    >
      {children}
      <ellipse cx="9" cy="8.8" stroke="currentColor" rx="8" ry="7.8" />
      <path
        fill="currentColor"
        d="M20.642 21.35a.516.516 0 0 0 .716 0 .485.485 0 0 0 0-.7l-.716.7Zm.716-.7-6.667-6.5-.716.699 6.667 6.5.716-.698Z"
      />
    </svg>
  );
};

export default memo(SVGLens);
