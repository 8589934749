import { SVGProps } from "react";

type Props = SVGProps<SVGSVGElement>;

/**
 * Optimized with https://jakearchibald.github.io/svgomg/
 */
const SVGChevronUp: React.FC<Props> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 46 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M43.627 23.04a1 1 0 0 0 1.415-1.414l-1.415 1.415ZM23.001 1l.707-.707a1 1 0 0 0-1.414 0l.707.707ZM.961 21.626a1 1 0 1 0 1.414 1.415L.96 21.626Zm44.08 0L23.709.293l-1.414 1.414 21.333 21.334 1.415-1.415ZM22.295.293.961 21.626l1.414 1.415L23.708 1.707 22.294.293Z"
      />
    </svg>
  );
};

export default SVGChevronUp;
