import { useAppDispatch, useAppSelector } from "@/lib/redux/hooks";
import { useRouter } from "next/router";
import { useEffect, useRef } from "react";
import { CSSTransition } from "react-transition-group";
import Content from "./Content";
import { Container, CloseArea } from "./styles";
import { close } from "@/lib/redux/desktopMenu/desktopMenuSlice";
import { MainLayoutQuery } from "@/lib/sanity/queries";

type Props = {
  items: MainLayoutQuery["header"]["navigation"]["items"];
};

const DesktopMenu: React.FC<Props> = (props) => {
  const { items } = props;
  const containerRef = useRef<HTMLDivElement>(null);
  const isOpen = useAppSelector((state) => state.desktopMenu.isOpen);

  const dispatch = useAppDispatch();
  const router = useRouter();

  useEffect(() => {
    const onRouteChangeStart = () => {
      dispatch(close());
    };
    router.events.on("routeChangeStart", onRouteChangeStart);
    return function cleanup() {
      router.events.off("routeChangeStart", onRouteChangeStart);
    };
  }, [dispatch, router]);

  return (
    <>
      <CSSTransition
        in={isOpen}
        nodeRef={containerRef}
        classNames="animation"
        addEndListener={(done) => {
          containerRef.current?.addEventListener("transitionend", done, false);
        }}
      >
        <Container ref={containerRef}>
          <Content items={items} />
        </Container>
      </CSSTransition>
      {isOpen && <CloseArea onClick={() => dispatch(close())} />}
    </>
  );
};

export default DesktopMenu;
