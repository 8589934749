import { useAppSelector } from "@/lib/redux/hooks";
import HomeLink from "./HomeLink";
import { Container, Controls } from "./styles";
import UserPage from "@/components/UserPage";

const PrivateHeader: React.FC = () => {
  const desktopMenuIsOpen = useAppSelector((state) => state.desktopMenu.isOpen);

  return (
    <>
      <Container $desktopMenuIsOpen={desktopMenuIsOpen}>
        <HomeLink />
        <Controls>
          <UserPage />
        </Controls>
      </Container>
    </>
  );
};

export default PrivateHeader;
