import documentFilters, {
  documentFilterAdapter,
} from "@/lib/redux/documentFilters/documentFiltersSlice";
import {
  addListener,
  configureStore,
  createListenerMiddleware,
  removeListener,
  TypedAddListener,
  TypedRemoveListener,
  TypedStartListening,
} from "@reduxjs/toolkit";
import desktopMenu from "./desktopMenu/desktopMenuSlice";
import jobsFilters, { jobsFilterAdapter } from "./jobsFilters/jobsFiltersSlice";
import mobileMenu from "./mobileMenu/mobileMenuSlice";
import logged from "./logged/loggedSlice";
import newsFilters, { newsFilterAdapter } from "./newsFilters/newsFiltersSlice";
import newsSearch from "./newsSearch/newsSearchSlice";
import productFilters, {
  ProductFilter,
  productFilterAdapter,
  productFilterOptionAdapter,
} from "./productFilters/productFiltersSlice";
import productSearch from "./productSearch/productSearchSlice";
import wishlist from "./wishlist/wishlistSlice";
// Create the middleware instance and methods
const listenerMiddleware = createListenerMiddleware();

export const store = configureStore({
  reducer: {
    desktopMenu,
    mobileMenu,
    productFilters,
    productSearch,
    newsFilters,
    newsSearch,
    wishlist,
    documentFilters,
    jobsFilters,
    logged,
  },
  //enhancers: composeWithDevTools({}),
  // Add the listener middleware to the store.
  // NOTE: Since this can receive actions with functions inside,
  // it should go before the serializability check middleware
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().prepend(listenerMiddleware.middleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export const addAppListener = addListener as TypedAddListener<
  RootState,
  AppDispatch
>;

export const removeAppListener = removeListener as TypedRemoveListener<
  RootState,
  AppDispatch
>;

export const startListening =
  listenerMiddleware.startListening as TypedStartListening<
    RootState,
    AppDispatch
  >;

// // Can create a set of memoized selectors based on the location of this entity state
export const productFilterSelectors =
  productFilterAdapter.getSelectors<RootState>((state) => state.productFilters);

export const productFilterOptionSelectors =
  productFilterOptionAdapter.getSelectors<ProductFilter>(
    (state) => state.options
  );

export const documentFilterSelectors =
  documentFilterAdapter.getSelectors<RootState>(
    (state) => state.documentFilters
  );

export const newsFilterSelectors = newsFilterAdapter.getSelectors<RootState>(
  (state) => state.newsFilters
);

export const jobsFilterSelectors = jobsFilterAdapter.getSelectors<RootState>(
  (state) => state.jobsFilters
);
