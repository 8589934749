import {
  ButtonBoxedPrimary,
  ButtonBoxedSecondary,
  ButtonUnboxedPrimary,
  ButtonUnboxedSecondary,
  ButtonUnboxedPrimaryNegative,
  ButtonUnboxedSecondaryNegative,
  ButtonBoxedPrimaryNegative,
  ButtonBoxedSecondaryNegative,
} from "@/lib/styles";
import React, {
  forwardRef,
  HTMLProps,
  PropsWithChildren,
  PropsWithoutRef,
  ReactElement,
} from "react";
import { IconWrapper } from "./styles";

const variants = {
  primaryBoxed: ButtonBoxedPrimary,
  secondaryBoxed: ButtonBoxedSecondary,
  primaryBoxedNegative: ButtonBoxedPrimaryNegative,
  secondaryBoxedNegative: ButtonBoxedSecondaryNegative,
  primaryUnboxed: ButtonUnboxedPrimary,
  secondaryUnboxed: ButtonUnboxedSecondary,
  primaryUnboxedNegative: ButtonUnboxedPrimaryNegative,
  secondaryUnboxedNegative: ButtonUnboxedSecondaryNegative,
};

export type VariantName = keyof typeof variants;

type AsAnchorProps = {
  as: "a";
} & HTMLProps<HTMLAnchorElement>;

type AsButtonProps = {
  as: "button";
} & HTMLProps<HTMLButtonElement>;

export type ButtonProps = PropsWithoutRef<
  PropsWithChildren<
    {
      variant: VariantName;
      hover?: boolean;
      icon?: ReactElement;
      iconPosition?: "left" | "right";
    } & (AsAnchorProps | AsButtonProps)
  >
>;

const Button = forwardRef<any, ButtonProps>(function Button(props, ref) {
  const { variant, children, icon, iconPosition = "right", hover = true, ...rest } = props;
  const Component = variants[variant] as any;

  const iconElement = icon && <IconWrapper>{icon}</IconWrapper>;

  return (
    <Component ref={ref} $hover={hover} {...rest}>
      {iconPosition === "left" && iconElement}
      {children}
      {iconPosition === "right" && iconElement}
    </Component>
  );
});

export default Button;
