import { SVGProps } from "react";

type Props = SVGProps<SVGSVGElement>;

/**
 * Optimized with https://jakearchibald.github.io/svgomg/
 */
const LinkedInSVG: React.FC<Props> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <title>LinkedIn</title>
    <path
      fill="#fff"
      d="M.328 6.64h4.146V20H.328V6.64ZM2.4 0A2.4 2.4 0 0 1 4.62 1.484a2.411 2.411 0 0 1-.519 2.624A2.402 2.402 0 0 1-.002 2.41 2.403 2.403 0 0 1 2.4 0M7.074 6.64h3.976v1.828h.057c.553-1.051 1.904-2.157 3.921-2.157C19.226 6.31 20 9.077 20 12.673V20h-4.142v-6.495c0-1.549-.026-3.543-2.154-3.543-2.157 0-2.486 1.69-2.486 3.432v6.608H7.076L7.074 6.64Z"
    />
  </svg>
);

export default LinkedInSVG;
