import { useAppDispatch, useAppSelector } from "@/lib/redux/hooks";
import { close, view } from "@/lib/redux/mobileMenu/mobileMenuSlice";
import { useRef } from "react";
import { CSSTransition } from "react-transition-group";
import { useRouter } from "next/router";
import { useTranslation } from "@/lib/translations";
import {
  CloseIcon,
  BackIcon,
  Container,
  BackButton,
  CloseButton,
} from "./styles";

const Header: React.FC = () => {
  const activeItem = useAppSelector((state) => state.mobileMenu.activeItem);
  const dispatch = useAppDispatch();
  const backButtonRef = useRef<HTMLButtonElement>(null);
  const { locale = "it" } = useRouter();
  const t = {
    goBack: useTranslation("page.goBack", locale) || "",
  };

  return (
    <Container>
      <CSSTransition
        in={!!activeItem}
        classNames="animation"
        nodeRef={backButtonRef}
        unmountOnExit
        mountOnEnter
        addEndListener={(done) =>
          backButtonRef.current?.addEventListener("transitionend", done, false)
        }
      >
        <BackButton ref={backButtonRef} onClick={() => dispatch(view(null))}>
          <BackIcon />
          <span>{t.goBack}</span>
        </BackButton>
      </CSSTransition>
      <div> {/* keeps close button to the right */}</div>
      <CloseButton onClick={() => dispatch(close())}>
        <CloseIcon title="Close menu" />
      </CloseButton>
    </Container>
  );
};

export default Header;
