import { resolveInternalLink } from "@/lib/helpers";
import Link from "next/link";
import { PropsWithChildren } from "react";

export type AppLinkProps = PropsWithChildren<{
  params: string | null;
  page: {
    _type: string;
    slug: string | null;
  } | null;
  title?: string | null;
}>;

const AppLink: React.FC<AppLinkProps> = (props: AppLinkProps) => {
  const { page, children, params } = props;
  if (!page?._type) return null;

  let href = resolveInternalLink(page._type, page.slug);
  if (!href) return null;

  if (params) {
    href += href.indexOf("?") !== -1 ? `&${params}` : `?${params}`;
  }

  return (
    <Link href={href} passHref>
      {children}
    </Link>
  );
};

export default AppLink;

export const pageMatchesPath = (page: AppLinkProps["page"], asPath: string) => {
  if (page) {
    switch (page._type) {
      case "marketingPage":
        return page.slug ? asPath.startsWith(`/${page.slug}`) : false;
      case "contactPage":
        return asPath.startsWith("/contacts");
      case "newsPage":
        return asPath.startsWith("/news");
      case "article":
        return asPath.startsWith("/news/");
      case "productListingPage":
      case "catalog":
        return asPath.startsWith("/products");
      default:
        return false;
    }
  } else {
    return false;
  }
};
