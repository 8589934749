import styled from "styled-components";

export const Container = styled.div`
  &.animation-enter {
    opacity: 0;
  }
  &.animation-exit {
    opacity: 1;
  }
  &.animation-enter-active {
    opacity: 1;
  }
  &.animation-exit-active {
    opacity: 0;
  }
  &.animation-enter-active,
  &.animation-exit-active {
    transition: opacity 100ms;
  }
`;
