import { SVGArrowLeft, SVGCross } from "@/lib/svg";
import { resetButton } from "@/lib/styles";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${({ theme }) => theme.space.headerMinHeight};
`;

export const CloseButton = styled.button`
  ${resetButton}
  cursor: pointer;
  padding: 0 1rem;
  display: flex;
  align-items: center;
`;

export const CloseIcon = styled(SVGCross)`
  width: 1rem;
  height: auto;
`;

export const BackButton = styled.button`
  ${resetButton}
  cursor: pointer;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  span {
    transform: translateY(1.5px);
  }
  &.animation-enter {
    opacity: 0;
  }
  &.animation-enter-active {
    opacity: 1;
    transition: opacity 200ms;
  }
  &.animation-exit {
    opacity: 1;
  }
  &.animation-exit-active {
    opacity: 0;
    transition: opacity 200ms;
  }
`;

export const BackIcon = styled(SVGArrowLeft)`
  width: 0.75rem;
  height: auto;
  margin-right: 0.625rem;
`;
