import { SVGArrowRight } from "@/lib/svg";
import { resetButton } from "@/lib/styles";
import styled from "styled-components";

export const Container = styled.div`
  margin: 0 1rem;
  display: flex;
  flex-direction: column;
`;

export const Button = styled.button`
  ${resetButton}
  height: 6.5rem;

  &:first-child {
    height: auto;
    padding-top: 1rem;
    padding-bottom: 2.5rem;
  }

  font-size: 1.25rem;
  line-height: 1.2;
  text-transform: uppercase;
  border-bottom: solid 1px;
  text-align: left;
  font-family: ${({ theme }) => theme.fontFamilies.secondary};
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Anchor = styled(Button).attrs(() => ({ as: "a" }))`
  text-decoration: none;
`;

export const ArrowIcon = styled(SVGArrowRight)`
  width: 1.125rem;
`;
