import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

export type NewsFilter = {
  id: string;
  values: string[];
  selectedValues: string[];
};

export const newsFilterAdapter = createEntityAdapter<NewsFilter>({
  // Assume IDs are stored in a field other than `filter.id`
  selectId: (filter) => filter.id,
  // Keep the "all IDs" array sorted based on filter titles
  //sortComparer: (a, b) => a.title.localeCompare(b.title),
});

const newsFilters = createSlice({
  name: "newsFilters",
  initialState: newsFilterAdapter.getInitialState(),
  reducers: {
    // Can pass adapter functions directly as case reducers.  Because we're passing this
    // as a value, `createSlice` will auto-generate the `filterAdded` action type / creator
    setAllNewsFilters: newsFilterAdapter.setAll,
    addNewsFilter: newsFilterAdapter.addOne,
    removeNewsFilter: newsFilterAdapter.removeOne,
    removeAllNewsFilters: newsFilterAdapter.removeAll,
    updateNewsFilter: newsFilterAdapter.updateOne,
    updateManyNewsFilters: newsFilterAdapter.updateMany,
  },
});

export const {
  setAllNewsFilters,
  addNewsFilter,
  removeNewsFilter,
  removeAllNewsFilters,
  updateNewsFilter,
  updateManyNewsFilters,
} = newsFilters.actions;

export default newsFilters.reducer;
