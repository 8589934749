import { Button } from "./styles";

type Props = {
  title: string;
  url: string;
  blank: boolean;
};

const ExternalLink: React.FC<Props> = (props) => {
  const { url, title, blank } = props;
  return <Button href={url} target={`${blank ? "_blank" : ""}`}>{title}</Button>;
};

export default ExternalLink;
