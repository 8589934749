import { it, enGB } from "date-fns/locale";
import { SITE_URL } from "./constants";

export function resolveInternalLink(_type: string, slug?: string | null) {
  switch (_type) {
    case "marketingPage":
      return "/" + slug;
    case "contactPage":
      return "/contacts#" + slug; // TODO add functionality
    case "catalog":
      return "/products?catalog=" + slug;
    case "corporatePage":
      return "/corporate/" + slug;
    case "newsPage":
      return "/news";
    case "productListingPage":
      return "/products";
    case "jobsPage":
      return "/people/open-positions";
    case "investorPage":
      return "/investors";
    default:
      console.error("Couldn't resolve internal link:", { _type, slug });
  }
}

/** @see https://www.sitemaps.org/protocol.html */
type SitemapUrlEntry = {
  slug: string;
  lastMod?: string;
  priority?: string;
  changeFreq?:
    | "always"
    | "hourly"
    | "daily"
    | "weekly"
    | "monthly"
    | "yearly"
    | "never";
};

export function generateSiteMap(pages: SitemapUrlEntry[], locales: string[]) {
  return (
    '<?xml version="1.0" encoding="UTF-8"?>\n' +
    '<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9" xmlns:xhtml="http://www.w3.org/1999/xhtml">\n' +
    pages
      ?.map(
        (entry) =>
          "\t<url>\n" +
          `\t\t<loc>${`${SITE_URL + entry.slug}`}</loc>\n` +
          (entry.lastMod ? `\t\t<lastmod>${entry.lastMod}</lastmod>\n` : "") +
          (entry.priority
            ? `\t\t<priority>${entry.priority}</priority>\n`
            : "") +
          (entry.changeFreq
            ? `\t\t<changefreq>${entry.changeFreq}</changefreq>\n`
            : "") +
          locales
            .map((locale) => {
              let slug = locale + entry.slug
              if (locale === "it") {
                slug = entry.slug
                if (slug.indexOf("/") === 0) {
                  slug = slug.slice(1)
                }
              }
              return [
                '\t\t<xhtml:link rel="alternate"',
                `hreflang="${locale}"`,
                `href="${SITE_URL}/${slug}"`,
                "/>",
              ].join(" ")
            })
            .join("\n") +
          "\n\t</url>\n"
      )
      .join("") +
    "</urlset>"
  );
}

// https://thewebdev.info/2021/02/27/how-to-detect-a-touch-screen-device-using-javascript/
export const isTouchDevice = () => {
  return "ontouchstart" in window || navigator.maxTouchPoints > 0;
};

// https://gist.github.com/lanqy/5193417?permalink_comment_id=4225701#gistcomment-4225701
export function bytesToSize(bytes: number): string {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "n/a";
  const i = Math.min(
    Math.floor(Math.log(bytes) / Math.log(1024)),
    sizes.length - 1
  );
  if (i === 0) return `${bytes} ${sizes[i]}`;
  return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
}

export const getDateFnsLocale = (locale: string) => {
  switch (locale) {
    case "it":
      return it;
    case "en":
      return enGB;
  }
};

/**
 * Terms can be grouped using the " > " as split char.
 *   - "Plastic > PVC"
 *   - "Plastic > PP"
 *   - "Plastic > PS"
 * Terms can be ordered by using a prefix like this:
 *   - "{0} first value"
 *   - "{1} second value"
 */
export function productFilterValueToLabel(s: string) {
  return s.replace(/.+\s>\s/i, "").replace(/^\{\d\}\s?/, "");
}

export async function fetcher(path: string, payload = {}) {
  const stringifiedPayload = JSON.stringify(payload);
  const headers = new Headers();
  headers.append("Content-Type", "application/json");

  const res = await fetch(path, {
    method: "POST",
    headers,
    body: stringifiedPayload,
  });

  if (res.ok) {
    return res.json();
  } else {
    throw new Error(res.statusText)
  }
}

