import algoliasearch from "algoliasearch";
import { ParsedUrlQuery } from "querystring";
import { z } from "zod";
import { SITE_URL } from "./constants";

const appId = process.env.ALGOLIA_APP_ID as string;
const indexName = process.env.ALGOLIA_INDEX_NAME as string;
const apiKey = process.env.ALGOLIA_API_KEY as string;

// Initialize the client
// https://www.algolia.com/doc/api-client/getting-started/instantiate-client-index/
export const algolia = algoliasearch(appId, apiKey);

// Initialize an index
// https://www.algolia.com/doc/api-client/getting-started/instantiate-client-index/#initialize-an-index
export const index = algolia.initIndex(indexName);

export const productObjectSchema = z
  .object({
    objectID: z.string(),
    name: z.string(),
    slug: z.string(),
    image: z.string(),
    catalog: z.array(z.string()),
  })
  .catchall(z.array(z.string()).or(z.string()));

// Repesentation of products inside algolia
export type ProductObject = z.infer<typeof productObjectSchema>;

export const searchResponseSchema = z.object({
  hits: z.array(productObjectSchema.extend({ _highlightResult: z.object({}) })),
  nbHits: z.number(),
  page: z.number(),
  nbPages: z.number(),
  hitsPerPage: z.number(),
  facets: z.record(
    z.string(),
    z.record(z.string(), z.number()).or(z.undefined())
  ),
});

export type SearchResponse = z.infer<typeof searchResponseSchema>;

export const searchApiFetcher = async (query: ParsedUrlQuery) => {
  // eslint-disable-next-line no-console
  console.count("🌐 search api invoked");
  const { bot, ...rest } = query;
  const url = new URL(SITE_URL + "/api/search");
  Object.entries(rest).forEach((entry) => {
    const [key, value] = entry;
    if (value instanceof Array) {
      value.forEach((value) => {
        url.searchParams.append(key, value);
      });
    } else if (value) {
      url.searchParams.append(key, value);
    }
  });
  const res = await (await fetch(url.toString())).json();
  return searchResponseSchema.parse(res);
};
