import { SVGProps } from "react";

type Props = SVGProps<SVGSVGElement>;

/**
 * Optimized with https://jakearchibald.github.io/svgomg/
 */
const SVGMp3: React.FC<Props> = (props) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="12" cy="12" r="10.5" stroke="#C11113" />
    <path
      d="M9 7L9.265 6.576C9.11087 6.47967 8.91659 6.47457 8.75762 6.56268C8.59864 6.65079 8.5 6.81824 8.5 7H9ZM9 17H8.5C8.5 17.1818 8.59864 17.3492 8.75762 17.4373C8.91659 17.5254 9.11087 17.5203 9.265 17.424L9 17ZM17 12L17.265 12.424C17.4112 12.3326 17.5 12.1724 17.5 12C17.5 11.8276 17.4112 11.6674 17.265 11.576L17 12ZM9.5 17V7H8.5V17H9.5ZM16.735 11.576L8.735 16.576L9.265 17.424L17.265 12.424L16.735 11.576ZM8.735 7.424L16.735 12.424L17.265 11.576L9.265 6.576L8.735 7.424Z"
      fill="#C11113"
    />
  </svg>
);

export default SVGMp3;
